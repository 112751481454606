import { makeAutoObservable } from "mobx";


class searchStore {

    searchQuery = '';
    
    constructor() {
        makeAutoObservable(this);
    }

    setSearchQuery(searchQuery) {
        this.searchQuery = searchQuery;
    }

}

export default new searchStore()