import React from 'react';
import cartStore from 'store/cartStore';
import orderStore from 'store/orderStore';

import styles from '../Cart.module.scss';

import cart_icon from "img/cart_icon.svg"

import { ReactComponent as ChatBubbleSVG } from '../../../img/chat_bubble_icon.svg';
import classNames from 'classnames';
import menuStore from 'store/menuStore';
import { observer } from 'mobx-react-lite';

const TotalPriceCartHeader = ({ hide }) => {
    const onClearHandler = () => {
        cartStore.setCart([]);
        sessionStorage.removeItem("cart");
    }

    const onChangeOrderComment = () => {
        console.log("BUBBLE")
        menuStore.setShowForm({ type: "comment" })
    }

    console.log("cartStore.addonItemsTotalPrice", cartStore.addonItemsTotalPrice)

    return (
        <div className={classNames(styles.menu_title, styles.menu_title_container)}>
            {hide ? (cartStore.cart?.length > 0 ?
                <div className={styles.menu_title_inner}>
                    <div className={styles.menu_title}>
                        Всего: {cartStore.totalCartPrice} {cartStore.addonItemsTotalPrice ? ` + ${cartStore.addonItemsTotalPrice}` : ''}руб.
                    </div>

                    <div className={styles.btn_container}>
                        {[1, 3, 4].includes(menuStore.menu.type) &&
                            <div className={styles.clear_cart_btn} onClick={e => { onChangeOrderComment(e) }}>
                                <ChatBubbleSVG />
                            </div>
                        }
                        <div className={styles.clear_cart_btn} onClick={(e) => { onClearHandler() }}>
                            <div className={styles.cross}>
                                <i className="fas fa-ban"></i>
                            </div>
                            <img src={cart_icon} alt="cart_icon" />
                        </div>
                    </div>

                </div>
                :
                <div className={styles.menu_title_inner}>
                    <div className={styles.menu_title}>
                        Корзина пуста
                    </div>
                </div>
            )
                :
                <div className={styles.menu_title_inner}>
                    <div className={styles.menu_title}>
                        Всего: {orderStore.orderInfo.sum} руб.
                    </div>
                </div>
            }
        </div>
    );
};

export default observer(TotalPriceCartHeader);