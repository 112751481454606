import { makeAutoObservable, toJS } from "mobx";

import ConnectionManager from '../http/axios';
import cartStore from "./cartStore";
import menuStore from "./menuStore";
import payStore from "./payStore";

class orderStore {

    orderInfoTimeoutId

    orderInfo = {}

    contragentInn = localStorage.getItem("contragentInn") ? localStorage.getItem("contragentInn") : '';

    contragents = []

    activeContragent = {}
    // addresses = [];
    completeBefore = null;

    comment = '';

    constructor() {
        makeAutoObservable(this);
    }

    setOrderInfo(orderInfo) {
        console.debug("setOrderInfo", toJS(orderInfo))
        this.orderInfo = orderInfo;
    }

    setContragentInn(contragentInn) {
        this.contragentInn = contragentInn;
    }

    setContragents(contragents) {
        this.contragents = contragents
    }

    setActiveContragent(activeContragent) {
        this.activeContragent = activeContragent;
        console.log("activeContragent", activeContragent)
    }

    setCompleteBefore(completeBefore) {
        this.completeBefore = completeBefore;
    }

    setOrderComment(comment) {
        this.comment = comment;
    }

    async getOrderInfo(orderId) {
        try {
            const res = await ConnectionManager.GetInstance().GetClient().get(`/order/info/${orderId}`);
            return res.data
        } catch (error) {
            console.error("getOrderInfo error", error.message)
            menuStore.setShowForm({ type: 'error', payload: /* `Ошибка получения информации по заказу` */ error.response.data.title /* JSON.stringify(e?.response?.data) */ })
            // menuStore.setShowForm({ type: 'error', payload: error.response.data.title })

            throw error
        }
    }

    promiseOrderInfo(id) {
        return new Promise((resolve, reject) => {
            let locked = false;

            const interval = setInterval(() => {
                if (!locked) {
                    this.getOrderInfo(id)
                        .then(order => {
                            this.setOrderInfo(order);
                            if (/* order.creationStatus === "Success" || order.creationStatus === "NEW" ||  */order.creationStatus === "Pushed") {
                                // cartStore.setIsCartOpen(false);
                                // menuStore.setShowForm({ type: '' });

                                this.setOrderInfo(order);

                                clearInterval(interval);

                                cartStore.setCart([]);
                                sessionStorage.setItem('cart', "[]")

                                resolve();
                            }
                        })
                        .catch(error => {
                            const order = error.response.data

                            // menuStore.setShowForm({ type: 'orderLoading' });
                            this.setOrderInfo(order);
                            clearInterval(interval);
                            reject(error.response.data);
                        })
                        .finally(() => locked = false)
                    locked = true;
                }
            }, 3000);
        });
    }

    async checkingOrderStatus() {
        const oldOrderId = localStorage.getItem("orderId")
        const menuType = localStorage.getItem("menuType")
        let oldOrderInfo;
        // menuStore.setShowForm({ type: 'orderLoading' });

        if (oldOrderId && ![3, 4].includes(+menuType)) oldOrderInfo = await this.getOrderInfo(oldOrderId);

        // console.log("oldOrderInfo", oldOrderInfo)
        // console.log( "oldOrderInfo?.creationStatus", oldOrderInfo?.creationStatus,"oldOrderInfo?.status", oldOrderInfo?.status,"![3, 4].includes(menuType)", ![3, 4].includes(menuType) )

        if (oldOrderInfo?.creationStatus === "Pushed" && oldOrderInfo?.status === "Pushed" && ![3, 4].includes(+menuType)) {
            // дозаказ
            // menuStore.setShowForm({ type: 'orderLoading' });

            let values = { orderId: oldOrderInfo.id, items: cartStore.cart, }

            // menuStore.setShowForm({ type: 'orderLoading' });
            await payStore.additionalOrderPush(values);
            return this.promiseOrderInfo(oldOrderId);
        } else {
            //новый заказ
            let values =
            {
                currentMenu: menuStore.menu.id,
                // phone: localStorage.getItem("phone") ? localStorage.getItem("phone") : '',
                restaurant: menuStore.menu.integrationId ? menuStore.menu.integrationId : null,
                guestId: null,
                items: cartStore.cart,

                comment: this.comment,
                // Email: "geark24032003@yandex.ru",
                // Phone: "+79889425940",
                completeBefore: this.completeBefore,

                // contragentId: "this.activeContragent.id",
                // adasdasd: 'asdasd'
                // address: {
                //     street: '',
                //     house: '',
                //     building: '',
                //     flat: '',
                //     entrance: '',
                //     floor: '',
                //     doorphone: '',
                // },
                // email: localStorage.getItem("email") ? localStorage.getItem("email") : '',
            }

            // menuStore.setShowForm({ type: 'orderLoading' });

            const paymentDataRes = await payStore.postPaymentDataOnServer(values);
            return this.promiseOrderInfo(paymentDataRes.id);
        }
    }

    async getAddresses(menuId) {
        try {
            const res = await ConnectionManager.GetInstance().GetClient().get(`/order/address/${menuId}`);
            return res.data
        } catch (error) {
            console.error("getOrderInfo error", error.message)
            // menuStore.setShowForm({ type: 'error', payload: `Ошибка получения списка адресов` /* JSON.stringify(e?.response?.data) */ })
            menuStore.setShowForm({ type: 'error', payload: error.response.data.title })
        }
    }
    // {{baseUrl}}/order/address/17164b23-dbf8-4a6d-a238-02734281ea94

    async getContragent(data) {
        try {
            /* 
            {
                "Inn":"12345678998765",
                "ShowcaseId":"7dfb971a-ed4b-49a9-9288-1b5d941d6308"
            }
            */
            const res = await ConnectionManager.GetInstance().GetClient().post(`/order/contragents`, data);
            this.setContragents(res.data)
            return res.data
        } catch (error) {
            console.error("getOrderInfo error", error.message)
            menuStore.setShowForm({ type: 'error', payload: /* `Ошибка получения контрагентов` */ JSON.stringify(error?.response?.data) })
            throw error
        }
    }

    async changePaymentSystemInOrder(paymentSystemPayload) {

        /* 
            {
                "orderId":"a31160e0-8c03-44b6-be48-eca88a5af330",
                "payment":{
                    "name": "CP",
                    "id": "95f9d38b-e480-4be8-a178-5d5bc4e6654c"
                }
            }
        */
        const res = await ConnectionManager.GetInstance().GetClient().post(`/order/changepayment`, paymentSystemPayload);
        return res.data
    }

}

export default new orderStore();
