import { makeAutoObservable, toJS } from "mobx";
import menuStore from "./menuStore";

import { v4 as uuidv4 } from 'uuid';
// import ConnectionManager from "http/axios";

const CART = "cart"

class cartStore {

    cart = JSON.parse(sessionStorage.getItem(CART)) || [];

    isCartOpen = false;

    // addonItemsList = []

    get addonItemsTotalPrice() {
        let totalPrice = 0
        this.addonsItemList.forEach(item => totalPrice = totalPrice + (item.price * item.amount))
        return totalPrice
    }

    get addonsItemList() {
        let res = []
        console.log("getAddonsItemList menuStore.menu.addons", toJS(menuStore.menu.addons))

        menuStore.menu.addons.forEach(addon => {
            console.log(" menuStore.menu.addons.map  addon.condition", toJS(addon.condition))
            if (this.checkAddonCondition(addon?.condition)) {
                console.log("addon.additionalItems", addon.additionalItems)
                res.push(...addon.additionalItems)
            }
        })
        console.log("addonsItemList", res)
        // this.setAddonsItemList(res);
        return res;
    }

    // setAddonsItemList(addonItemsList) {
    //     this.addonItemsList = addonItemsList
    // }

    constructor() {
        makeAutoObservable(this)
    }

    setCart(cart) {
        this.cart = cart;
        this.setLocalStorageCart(cart);
    }

    addCart(product) {
        this.cart.push({ ...product, cartId: uuidv4() });
        this.setLocalStorageCart(this.cart);
    }
    setIsCartOpen(isCartOpen) {
        this.isCartOpen = isCartOpen;
    }
    toggleIsCartOpen() {
        this.isCartOpen = !this.isCartOpen;
    }

    setLocalStorageCart(cart) {
        sessionStorage.setItem(CART, JSON.stringify(cart))
    }

    clearCart() {
        this.setCart([]);
    }

    findProductInMenu(id, keyMatch = "id") {
        const items = menuStore.menu?.items;
        let result = items.find((item) => item[keyMatch] === id)
        return result;
    }

    increaseProductCountInCart(cartId) {
        let targetProduct = this.cart.filter(item => item.cartId === cartId)?.[0];
        targetProduct.amount += 1
    }

    setCounterInCart(cartId, amount) {
        let targetProduct = this.cart.filter(item => item.id === cartId)?.[0];
        targetProduct.amount = amount
    }

    subtractProductFromCart(cartId) {
        console.log("subtractProductFromCart cartId", cartId)

        let targetProduct = this.cart.filter(item => item.cartId === cartId)?.[0];
        targetProduct.amount = targetProduct.amount - 1;
        if (targetProduct.amount <= 0) this.setCart(this.cart.filter(item => item.cartId !== cartId))
    }

    addProductToCart(id, keyMatch = "id", payload) {
        let targetProduct = menuStore.findProductInMenu(id, keyMatch);

        // let targetProduct = this.cart.filter( item => item.cartId === cartId)?.[0];
        // if(!targetProduct) targetProduct = {...menuStore.findProductInMenu(id), cartId: uuidv4()};

        if (payload) targetProduct = { ...targetProduct, ...payload }
        if (!targetProduct.amount) targetProduct.amount = 1

        if (!this.cart) {
            this.setCart([{ ...targetProduct, cartId: uuidv4() }])
            return
        };

        let addProductFlag = true;
        for (let product of this.cart) {
            //console.log(product.id)

            if (product.id === targetProduct.id) {
                // значит добавляем count на элемент
                product.amount += 1
                addProductFlag = false;
                this.setLocalStorageCart(this.cart);
                return console.log('такое уже есть', product.amount)
            }
        }
        if (addProductFlag) this.addCart(targetProduct);
    }

    pushItemToCart(item) {
        if (item.sizes?.length > 0) item.size = item.sizes?.filter(elem => elem.id === item.size)?.[0]
        if (item.size) item.price = item.size.price

        this.cart.push({ ...item, amount: 1, cartId: uuidv4(), /* price: item.totalPrice, */ });
        this.setLocalStorageCart(this.cart);

    }

    deleteProductFromCart(id) {
        this.setCart(
            this.cart.filter((product) => {
                if (product.id === id) {
                    product.amount = 0;
                    return false
                } else { return true; }
            })
        )
    }

    menuitem_subtractProductFromCart(id) {
        let targetProduct = this.cart.filter(item => item.id === id)?.[0];
        targetProduct.amount = targetProduct.amount - 1;
        this.setCart(this.cart.filter(item => item.amount > 0))
    }

    get totalCartPrice() {
        const result = this.cart.reduce((a, b) => {
            if (!b) return 0
            let totalPrice;
            b?.totalPrice ? totalPrice = +b.totalPrice : totalPrice = +b.price;
            return a + (totalPrice * +b.amount);
        }, 0)
        return result
    }

    get totalCartCounter() {
        console.log("totalCartCounter", this.cart)
        if (this.cart.length === 0) return 0
        if (this.cart[0] === null) return 0
        const result = this.cart.reduce((a, b) => a + (+b.amount), 0);
        return result ? result : 0;
    }

    replaceCartFromOrderItems(orderItems) {
        if (orderItems?.length > 0) {
            this.clearCart();
            for (let item of orderItems) {
                this.addProductToCart(item.id, "item", { amount: item.amount });
            }
            // cartStore.clearCart();
        }
    }

    checkAddonCondition(condition) {
        let resArr = []

        const compare = (rule) => {
            if (rule === null) return;

            let arg
            let { field, operator, type, value } = rule;

            switch (field) {
                case "amount": arg = this.totalCartPrice; break;
                default: break;
            }

            if (type === "integer" || type === "double") value = +value[0]
            // console.log("compare", compare)
            console.log(arg, operator, value, +arg > +value)
            switch (operator) {
                case '=': return arg === value;
                case '!=': return arg !== value;
                case '<': return arg < value;
                case '<=': return arg <= value;
                case '>': return arg > value;
                case '>=': return arg >= value;
                default: break;
            }
        }

        if (condition !== null && condition.rules) {
            for (let rule of condition.rules) {
                resArr.push(compare(rule))
            }
        }

        switch (condition.combinator) {
            case "and":
                for (let bool of resArr) { if (bool === false) return false }
                return true
            case "or":
                for (let bool of resArr) { if (bool === true) return true }
                return false
            default: return false
        }
    }


}

export default new cartStore();
