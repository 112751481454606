import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Swiper, SwiperSlide } from "swiper/react";
import { useSwipeable } from "react-swipeable";

import menuStore from "../../store/menuStore";
import cartStore from "../../store/cartStore";

import TopBar from "../../components/TopBar";
import Cart from "../../components/Cart/Cart";
import ShowcaseItem from "../../components/ShowcaseItem";
import ShowcaseNotFound from "../../components/ShowcaseNotFound";

import "./Showcase.scss";

// Import Swiper styles
import "swiper/css";
import ShowForm from "../../components/forms/ShowForm";
import searchStore from "../../store/searchStore";
import MenuItem from "../../components/MenuItem";
import ItemSearchBox from "../../components/ItemSearchBox/ItemSearchBox";
// import ItemEditor from "../../components/ItemEditor/ItemEditor";

const Showcase = () => {
  const { id, parent } = useParams();

  const navSwiper = useRef();

  const [swiper, setSwiper] = useState(0);

  const navigate = useNavigate();

  const showcaseItemsRef = useRef();

  useEffect(() => {
    menuStore.setShowcaseItemsRef(showcaseItemsRef);
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    menuStore.fetchMenu(id).then((data) => {
      if (menuStore.menu.id) {
        let isWeekend = menuStore.isWeekendCheck(menuStore.menu?.hoursOfWork);
        console.log("isWeekend", isWeekend);
        if (isWeekend) navigate(`./notWorkedTime`);
      }
    });
    // })
  }, [id, navigate]);

  const config = {
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  };

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => {
      cartStore.setIsCartOpen(false);
    },
    ...config,
    onSwipedLeft: (eventData) => {
      cartStore.setIsCartOpen(true);
    },
    ...config,
  });

  const items = menuStore.menu.items;

  const onVisibilityChangedHandler = (e, v) => {
    console.debug(e.name, v);
  };

  const navSwiperOnClickHandler = (e) => {
    const elem2 = e.target?.closest("[data-id]")?.dataset.id;
    menuStore.scrollTo(elem2);
    console.log("navSwiperOnClickHandler e", e);

    // swiper.slideTo(slideIndex)
  };

  if (menuStore.isError) return <ShowcaseNotFound />;

  // useEffect = (() => {
  //   // window.onScroll = (e) => console.log("scroll", e)
  // }, [])

  const inViewOnScrollHandler = (e) => {};

  return (
    <>
      <div className="showcase">
        <div className="showcase-header">
          <TopBar />
        </div>
        <div className="showcase-navigation">
          <div className="showcase-navigation-wrapper">
            {items.filter((x) => x.parent == parent && x.type === 2)?.length !== 0 && (
              <Swiper ref={navSwiper} className="showcase-navigation" slidesPerView={"auto"} onSwiper={setSwiper}>
                {items
                  .slice()
                  .filter((item) => {
                    if (item.type !== 2) return false;
                    if (!parent && item.parent === null) return true;
                    if (item.parent === parent) return true;
                  })
                  .sort((a, b) => a.index > b.index)
                  // .sort(item => item.type !== 2)
                  .map((x, i) => {
                    let navItemClass;
                    menuStore.activeTopBarId === x.id
                      ? (navItemClass = `nav_menu_item nav_item active`)
                      : (navItemClass = `nav_menu_item nav_item`);

                    return (
                      <SwiperSlide key={x.id} data-id={x.id}>
                        <div data-id={x.id} className={navItemClass} onClick={(e) => navSwiperOnClickHandler(e)}>
                          {x.name}
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            )}
          </div>
        </div>
        {/* {searchStore.searchQuery?.trim()?.length > 0 && */}
        {/* } */}

        <div className="showcase-items" {...handlers} /* onScroll={(e) => console.log("scroll", e)} */ ref={showcaseItemsRef}>
          <ItemSearchBox />

          {items
            .filter((item) => {
              if (!parent && item.parent === null) return true;
              if (item.parent === parent) return true;
            })
            .sort((item) => item.type !== 2)
            .sort((a, b) => a.index > b.index)
            .map((x) =>
              x.type === 2 ? (
                <ShowcaseItem
                  key={x.id}
                  allItems={items}
                  currentItem={x}
                  onVisibilityChanged={(e, v) => onVisibilityChangedHandler(e, v)}
                  swiper={swiper}
                />
              ) : null
            )}
        </div>

        {parent && (
          <div className="back_btn" onClick={() => navigate(-1)}>
            <i className="fas fa-arrow-left"></i>
          </div>
        )}
        <ShowForm />
        <div className={"cart_wrapper"}>
          {/* !!! корзина */}
          {menuStore.menu.isShowCart !== false && <Cart />}
        </div>
      </div>
    </>
  );
};

export default observer(Showcase);
