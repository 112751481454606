import React from 'react';
import { observer } from 'mobx-react-lite';
import menuStore from '../../store/menuStore';
import MenuItem from '../MenuItem';

import "../../pages/Showcase/Showcase.scss";
import searchStore from '../../store/searchStore';
import classNames from 'classnames';

const ItemSearchBox = () => {
    const items = menuStore.menu.items;

    const searchParam = searchStore.searchQuery?.trim()?.toLowerCase()

    const menuType = menuStore.menu.type

    const renderItems = items
        .filter(item =>
            item.type === 1 &&
            searchParam?.length > 0 &&
            item?.name?.toLowerCase()?.includes(searchParam)
        )
        .map(item => <MenuItem data={item} key={item.id} />)

    return (
        <>
            {renderItems?.length > 0 && searchParam?.length > 1 &&
                <div className="container">
                    <div className='group-title'>
                        Вы искали:
                    </div>
                    <div className={classNames("showcase-item", { "showcase-list_type3": [3, 4].includes(menuType) })}>
                        {renderItems}
                    </div>
                </div>
            }
            {renderItems?.length === 0 && searchParam.length > 1 &&
                <div className="container">
                    <div className='group-title'>
                        Мы ничего не нашли
                    </div>
                </div>
            }
        </>
    );
};

export default observer(ItemSearchBox);