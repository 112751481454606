import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './MenuItem.module.scss';

import cartStore from '../../store/cartStore';
import menuStore from '../../store/menuStore';

import item_placeholder from '../../img/item_placeholder.svg';
// import { useNavigate } from 'react-router-dom';
import editorStore from '../../store/editorStore';
import { toJS } from 'mobx';
// import { HubConnectionBuilder } from '@microsoft/signalr';

// import moment from 'moment';

// import { Link, useParams } from "react-router-dom";

const MenuItem = ({
    data = {}
}) => {

    // const { params_id, params_parent } = useParams();
    // const navigate = useNavigate();

    const menuType = menuStore.menu.type;
    const integrationType = menuStore.menu.integrationType;

    const {
        images,
        name,
        price,
        weight,
        description,
        preparing,
        parent,
        id } = data

    const amount = menuStore.getItemCounter(id);

    const addProductHandler = (e) => {

        console.debug("addProductHandler data", toJS(data))
        if (data.sizes?.length > 1 || data.modifiers?.length > 0 || data.groupModifiers?.length > 0) {
            //!!! продукт +
            // navigate(`item/${data.id}`);
            menuStore.setShowForm({ type: 'itemEditor' })
            editorStore.setEditableItem(data);
        } else {
            cartStore.addProductToCart(id);
        }
        // const id = menuStore.findItemId(e);
    }

    const showProductEditorHandler = (e) => {
        menuStore.setShowForm({ type: 'itemEditor' });
        editorStore.setEditableItem(data);
    }

    const subtractProductHandler = (e) => {
        console.debug("subtractProductHandler in menu item")
        // const id = menuStore.findItemId(e);
        cartStore.menuitem_subtractProductFromCart(id);
    }

    const setCounterHandler = (e) => {
        console.log("setCounterHandler", id, e.target.value)
        if (+e.target.value < 0) return
        cartStore.setCounterInCart(id, +e.target.value)
    }

    const counterActive = amount ? styles.active : null

    return (
        <>
            <div className={styles.item_wrapper} data-id={id}>
                {![3, 4].includes(menuType) &&
                    <div className={styles.image_wrapper} onClick={(e) => { showProductEditorHandler(e) }}                >
                        <div className={styles.cart_counter_wrapper}>
                            <div className={styles.menu_item_image}>
                                {images?.length > 0 ?
                                    <img src={images?.[0]} alt="menu_item_image" />
                                    :
                                    <img className={styles.placeholder} src={item_placeholder} alt="" />
                                }
                            </div>
                        </div>
                    </div>
                }

                <div className={styles.menu_item_description}>
                    <div className={styles.menu_item_main_description}>
                        <div to={`/${parent}`} className={styles.menu_item_title}>
                            {name}
                        </div>
                    </div>


                    <div className={styles.menu_item_composition}>
                        {[3, 4].includes(integrationType) && description?.length > 0 ?
                            description
                            :
                            description?.length > 75 ? description.slice(0, 75).concat("...") : description
                        }
                    </div>
                    <div className={styles.menu_item_price}>
                        {price} руб.
                    </div>

                    <div className={`${styles.item_btn_wrapper} ${counterActive}`}>
                        <div className={styles.plus_cart_btn}
                            onClick={(e) => { addProductHandler(e) }}
                        >
                            <i className="fa fa-plus"></i>
                        </div>
                        {[3, 4].includes(menuType) ?
                            amount ? <input type="number" min="0" className={styles.item_count_input} value={amount} onChange={(e) => setCounterHandler(e)} /> : null
                            :
                            <div className={styles.item_count}>
                                {amount ?? 0}
                            </div>
                        }
                        <div className={styles.minus_cart_btn}
                            onClick={(e) => { subtractProductHandler(e) }}
                        >
                            <i className="fa fa-minus"></i>
                        </div>
                    </div>

                </div>
            </div>


        </>
    );
};

export default observer(MenuItem);