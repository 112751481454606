import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import menuStore from "../../../store/menuStore";

import styles from "components/Cart/CartItem.module.scss";
import ModifierSpan from "./ModifierSpan";

const OrderItem = ({ data }) => {
  const { name, id, modifiers, amount } = data;

  const item = menuStore.menu?.items?.find((item) => item.item === id);
  let itemTotalPrice = menuStore.calcItemTotalPrice(data);
  if (item) itemTotalPrice = menuStore.calcItemTotalPrice(data);

  // console.log("OrderItem item", toJS(item), itemTotalPrice)

  let menuItem;
  if (menuStore.menu?.items.length > 0) menuItem = menuStore.menu?.items.filter((item) => item.item === id)?.[0];
  console.log("menuItem", menuItem);
  return (
    <div className={styles.cart_menu_item} data-id={id}>
      <div className={styles.item_image}>{menuItem && <img src={menuItem.images?.[0]} alt="" />}</div>

      <div className={styles.descr_wrapper}>
        <div>
          <div className={styles.item_title}>{name}</div>
          <div className={styles.item_modifiers}>
            {modifiers?.length > 0 &&
              modifiers?.map((item) => <ModifierSpan  key={item.id} item={item} />)}
          </div>
        </div>
        <div className={styles.item_footer}>
          <div className={classNames(styles.item_modifiers, styles.item_amount)}>Количество: {amount}</div>
          <div className={styles.item_price}>
            {/* {itemTotalPrice ? itemTotalPrice * amount : price * amount}р. */}
            {itemTotalPrice /* * amount */}р.
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(OrderItem);
