import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import searchStore from '../../../store/searchStore';

import search_inactive_icon from '../../../img/search-passive.svg';

import styles from '../TopBar.module.scss';

const SearchBar = () => {

    const [showSearchInput, setShowSearchInput] = useState(false);

    const inputElement = useRef();

    const showSearchInputHandler = () => {
        if (!showSearchInput) inputElement?.current?.focus();
        searchStore.setSearchQuery('');
        setShowSearchInput(!showSearchInput);
    }
    // console.log("inputElement", inputElement)

    return (
        <div className={styles.search_container}>
            <div className={classNames(styles.search_wrapper, { [styles.show]: showSearchInput })}>
                <div className={classNames(styles.nav_menu_btn, styles.search_btn)} onClick={showSearchInputHandler}>
                    <div>
                        <img className={styles.search_icon} src={search_inactive_icon} alt="search_active_icon" />
                        {/* <i className="fas fa-search"></i> */}
                    </div>
                </div>
                <input ref={inputElement} placeholder={"Поиск..."} type="text" className={classNames(styles.search_input, { [styles.show]: showSearchInput })}
                    value={searchStore.searchQuery}
                    onChange={(e) => { searchStore.setSearchQuery(e.target.value) }}
                    onFocus={ () => {setShowSearchInput(true)}}
                    onBlur={() => {setShowSearchInput(false)}}
                />
            </div>
        </div>
    );
};

export default observer(SearchBar);