import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";
import ConfigurationManager from "config";

export default class SignalRConnectionManager {
  static _instance;
  connection;

  constructor() {
    this.connection = new HubConnectionBuilder()
      // .withUrl('https://gerak24032003-6ihmp502i14w1yj8.socketxp.com/orderhub')
      // .withUrl('http://192.168.210.244:5002/orderhub',
      // .withUrl('http://api.foodof.corp/orderhub',
      .withUrl(`${ConfigurationManager.GetInstance().getItem("API_BASE")}/orderhub`,
        {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets
        })
      .withAutomaticReconnect()
      .build();
  }

  GetClient() {
    return this.connection;
  }

  static GetInstance() {
    return this._instance ?? (this._instance = new SignalRConnectionManager());
  }
}