import { observer } from 'mobx-react-lite';
import React from 'react';
import menuStore from '../../store/menuStore';
import ItemEditor from '../ItemEditor/ItemEditor';
import UpsPopup from './UpsPopup';


import styles from './forms.module.scss';
import LoadingPopup from './LoadingPopup';
import Spinner from './LoadingPopup/Spinner';
import CommentPopup from './CommentPopup';

const ShowForm = () => {
    //{type: srt, payload: {}}
    const { type, payload } = menuStore.showForm
    console.log("ShowForm type", type)

    switch (type) {
        // case "payment":
        //     return <PaymentForm />
        case "itemEditor": return <div className={styles.item_editor_container}> <ItemEditor /> </div>
        case "orderLoading": return <LoadingPopup />
        case "upsPopup": return <UpsPopup message={"Что-то пошло не так, заказ не был создан"} />
        case "messagePopup": return <UpsPopup message={payload} />
        case "error": return <UpsPopup message={payload} status={"error"} />
        case "spinner": return <div className={styles.spinner_wrapper}><Spinner /></div>
        case "comment": return <CommentPopup />

        default: return null
    }
};

export default observer(ShowForm);