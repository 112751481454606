import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";

import styles from '../forms.module.scss';

import classNames from 'classnames';

import menuStore from '../../../store/menuStore';
import cartStore from '../../../store/cartStore';
import payStore from '../../../store/payStore';
import orderStore from 'store/orderStore';

import ShowForm from '../ShowForm';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CustomDateTimePicker from 'components/CustomDateTimePicker';
import CustomInput from '../formComponents/CustomInput';

const phoneRegExp = /^((\7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/

let tabs = ["main"];

const PaymentForm = () => {

    const [addresses, setAddresses] = useState(null); //справочник адресов

    const [date, setDate] = useState(null);
    //выпадающий список
    const [isShowCityList, setIsShowCityList] = useState(false);
    const [isShowStreetList, setIsShowStreetList] = useState(false);

    const integrationId = localStorage.getItem("integrationId")

    const menuType = menuStore.menu.type
    const [render, setRender] = useState({});

    switch (menuType) {
        case 0: tabs = ["main"]; break;
        case 1: tabs = ["main"]; break;
        case 2: tabs = ["main", "address",]; break;
        case 3: tabs = ["main", "address",]; break;
        case 4: tabs = ["main", "address",]; break;
        default: break;
    }

    //переключение табов
    const [tab, setTab] = useState('');
    const [tabPosition, setTabPosition] = useState(0);

    const navigate = useNavigate();
    if (!(menuStore.menu.items.length > 0)) navigate(`/showcase/${localStorage.getItem("menuId")}`)

    const onNextHandler = () => {
        if (tabPosition === tabs.length - 1) return
        setTabPosition(tabPosition + 1)
    }

    const onPrevHandler = () => {
        if (tabPosition === 0) return
        setTabPosition(tabPosition - 1)
    }

    useEffect(() => {
        setTab(tabs[tabPosition]);
    }, [tabPosition])

    useEffect(() => {
        if (integrationId) {
            // console.log("menuStore.menu", menuStore.menu.integrationId)
            orderStore.getAddresses(integrationId).then(data => { console.log("getAddresses", data); setAddresses(data) })
        }
    }, [integrationId])

    useEffect(() => {
        menuStore.setShowForm({ type: '' })
    }, [])

    const validationCity = (value) => {
        if (addresses) {
            for (let address of addresses) {
                if (address?.city === value) {
                    return
                }
            }
        }
        return 'Выберите город из списка'
    }

    const validationStreet = (value) => {
        if (!addresses) return '';
        if (addresses) {
            for (let address of addresses) {
                for (let street of address.streets) {
                    if (street === value) {
                        return
                    }
                }
            }
        }
        return 'Выберите улицу из списка'
    }

    const preparePaymentType = (data) => {
        const paymentType = menuStore.menu.availablePayments.find(item => item.id === data.formData_paymentType);
        return paymentType ? JSON.parse(JSON.stringify(paymentType)) : null;
    }

    const validationSchema = () => {
        let schemaObj = {
            phone: Yup.string().trim()
                .min(11, 'Введите 11 цифр')
                .matches(phoneRegExp, 'Введите корректный номер телефона')
                .required("Обязательно к заполнению"),
            email: Yup.string().trim().email('Введите корректный email'),

        }

        if (menuStore?.menu?.orderType?.orderServiceType === "DeliveryByCourier") {
            schemaObj.address = Yup.object().shape({
                house: Yup.string().trim().required("Обязательно к заполнению"),
                street: Yup.string().trim().required("Обязательно к заполнению"),
                city: Yup.string().trim().required("Обязательно к заполнению"),
            })
        }

        return Yup.object().shape(schemaObj)
    }

    return (
        <div className={styles.form_container}>
            <Formik
                // validateOnMount={true}
                initialValues={{
                    currentMenu: menuStore.menu.id,
                    phone: localStorage.getItem("phone") ? localStorage.getItem("phone") : '',
                    restaurant: menuStore.menu.integrationId ? menuStore.menu.integrationId : '',
                    guestId: null,
                    items: cartStore.cart,
                    address: {
                        street: '',
                        house: '',
                        building: '',
                        flat: '',
                        entrance: '',
                        floor: '',
                        doorphone: '',

                        city: '',
                        index: '',
                        comment: '',
                    },
                    paymentType: /* menuStore.menu.availablePayments?.[0] */{ name: '', id: null },
                    formData_paymentType: '',
                    // PaymentSystem: {
                    //     Id: "09322f46-578a-d210-add7-eec222a08871",
                    //     name: "Наличные"
                    // },
                    email: localStorage.getItem("email") ? localStorage.getItem("email") : '',
                }}
                onSubmit={async (values, actions) => {

                    let data = JSON.parse(JSON.stringify(values))
                    data.phone = '+' + data.phone;

                    let paymentType = preparePaymentType(data);
                    delete data.formData_paymentType

                    console.log("onSubmit pay form", { ...data, paymentType });

                    payStore.postPaymentDataOnServer({ ...data, paymentType })
                }}

                validationSchema={validationSchema()}
            >

                {props => {
                    const {
                        values,
                        dirty,
                        errors,
                        isValid,
                        validateForm,
                        handleChange,
                    } = props;

                    console.log("form values", values)
                    // console.log(props)

                    const streets = addresses?.filter(address => address.city === values.address.city)?.[0]?.streets

                    return (
                        <Form
                            className={styles.form}
                            onClick={(e) => validateForm()}>
                            <div className={styles.close_btn} onClick={() => { navigate(-1); menuStore.setShowForm({ type: '' }) }}>
                                <i className="fas fa-times"></i>
                            </div>
                            <div className={styles.form_title}>
                                Подтвердите оплату
                            </div>

                            {tab === "main" &&
                                <div className={styles.form_fields}>
                                    <CustomInput
                                        type="phone"
                                        name="phone"
                                        placeholder={"Введите номер телефона"}
                                        label={"Номер телефона"}
                                        isRequired={true}
                                        error={errors.phone}

                                        component={PhoneInput}
                                        value={values.phone}
                                        country={'ru'}
                                        onChange={phone => values.phone = phone}
                                        disableDropdown={true}
                                        countryCodeEditable={false}
                                        onBlur={validateForm}
                                    />

                                    <CustomInput label={"Email"} name="email" placeholder={"Введите email"} error={errors.email} />

                                    <CustomInput label={"Комментарий к заказу"} as={"textarea"} name="comment" placeholder={"Введите комментарий"} />

                                    {menuStore.menu.availablePayments?.length > 0 &&
                                        <div div className={classNames(styles.form_field_container, styles.form_field_container_big)}>
                                            <div className={styles.input_title}>
                                                Способ оплаты
                                            </div>
                                            <Field
                                                type="text"
                                                as="select"
                                                name="formData_paymentType"
                                                placeholder={"Выберите способ оплаты"}
                                            >
                                                <option key={0} value={''}>{'-Выберите способ оплаты-'}</option>
                                                {menuStore.menu.availablePayments?.length > 0 &&
                                                    menuStore.menu.availablePayments.map(item => <option key={item.id + item.name} value={item.id}>{item.name}</option>)
                                                }
                                            </Field>
                                        </div>
                                    }

                                    {menuStore.menu.canOrderByTime === true &&
                                        <div>
                                            <div className={styles.input_title}>
                                                К дате
                                            </div>
                                            <CustomDateTimePicker
                                                value={date}
                                                onChange={(e) => {
                                                    setDate(e);
                                                    orderStore.setCompleteBefore(e);
                                                    values.completeBefore = e;
                                                }}
                                            />
                                        </div>
                                    }

                                </div>
                            }

                            {tab === "address" &&
                                <>
                                    <div className={styles.address_container}>
                                        {menuStore?.menu.orderType?.orderServiceType !== "DeliveryPickUp" &&
                                            <>
                                                <div className={classNames(styles.form_field_container, styles.form_field_container_big)}>
                                                    <div className={styles.input_title}>
                                                        Город<span className={styles.required_star}>*</span>
                                                    </div>
                                                    <Field
                                                        validate={validationCity}
                                                        type="text"
                                                        name="address.city"
                                                        placeholder={"Введите название города"}
                                                        onBlur={() => {
                                                            // костыль
                                                            setTimeout(() => {
                                                                setIsShowCityList(false)
                                                            }, 150)
                                                        }}
                                                        onFocus={() => { setIsShowCityList(true) }}
                                                    // onChange={(e) => {
                                                    //     handleChange(e);
                                                    //     if (values.address.city.length < 3) return
                                                    //     setIsShowCityList(true)
                                                    // }}
                                                    />
                                                    <div className={classNames(styles.address_list, !isShowCityList && styles.hide)}>
                                                        {addresses &&
                                                            addresses
                                                                .filter(address => address.city.includes(values.address.city))
                                                                .map(address => (
                                                                    <ListItem
                                                                        data={address.city}
                                                                        key={address.city + Math.random()}
                                                                        onClick={(e, value) => {
                                                                            values.address.city = value;
                                                                            setIsShowCityList(false)
                                                                            // setRender({});
                                                                        }}
                                                                    />
                                                                ))
                                                        }
                                                    </div>
                                                    {props.errors?.address?.city ? <div className={styles.form_validation}>{props.errors.address.city}</div> : ''}

                                                </div>
                                                <div className={classNames(styles.form_field_container, styles.form_field_container_big)}>
                                                    <div className={styles.input_title}>
                                                        Улица<span className={styles.required_star}>*</span>
                                                    </div>
                                                    <Field
                                                        validate={validationStreet}
                                                        type="text"
                                                        name="address.street"
                                                        placeholder={"Введите название улицы"}
                                                        onBlur={() => {
                                                            // костыль
                                                            setTimeout(() => {
                                                                setIsShowStreetList(false)
                                                            }, 150)
                                                        }}
                                                        onFocus={() => {
                                                            // if (values.address.street.length < 3) return
                                                            setIsShowStreetList(true)
                                                        }}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            // if (values.address.street.length < 3) return
                                                            setIsShowStreetList(true)
                                                        }}
                                                    />

                                                    <div className={classNames(styles.address_list, !isShowStreetList && styles.hide)}>
                                                        {addresses &&
                                                            streets
                                                                ?.filter(street => street.trim().toLowerCase().includes(values.address.street.trim().toLowerCase()))
                                                                .slice(0, 21)
                                                                .map(street => (
                                                                    <ListItem
                                                                        data={street}
                                                                        key={street + Math.random()}
                                                                        onClick={(e, value) => {
                                                                            values.address.street = value;
                                                                            // setRender({});
                                                                            setIsShowStreetList(false)
                                                                        }}
                                                                    />
                                                                ))
                                                        }
                                                    </div>
                                                    {props.errors?.address?.street && <div className={styles.form_validation}>{props.errors.address.street}</div>}

                                                </div>

                                                <CustomInput name="address.house" customProps={props} label={"Номер дома"} isRequired={true} error={errors?.address?.house} />
                                                <CustomInput name="address.building" placeholder={"Номер корпуса"} customProps={props} label={"Корпус"} />
                                                <CustomInput name="address.flat" placeholder={"Номер квартиры"} customProps={props} label={"Квартира"} />
                                                <CustomInput name="address.entrance" placeholder={"Номер подъезда"} customProps={props} label={"Подъезд"} />
                                                <CustomInput name="address.floor" customProps={props} label={"Этаж"} />
                                                <CustomInput name="address.doorphone" customProps={props} label={"Номер домофона"} />

                                            </>
                                        }
                                        <>
                                            <div div className={classNames(styles.form_field_container, styles.form_field_container_big)}>
                                                <CustomInput label={"Комментарий к адресу"} as={"textarea"} name="address.comment" placeholder={"Введите комментарий"} />
                                            </div>
                                        </>
                                    </div>

                                </>

                            }

                            <div className={styles.btn_wrapper}>

                                {tabPosition !== 0 &&
                                    <button
                                        className={styles.back_btn}
                                        type="button"
                                        onClick={onPrevHandler}
                                    >
                                        <i className="fas fa-chevron-left"></i>
                                    </button>
                                }
                                {tabPosition !== tabs.length - 1 &&
                                    <button
                                        className={styles.next_btn}
                                        type="button"
                                        onClick={onNextHandler}
                                    >
                                        Далее
                                    </button>
                                }
                                {tabPosition === tabs.length - 1 &&
                                    <button
                                        className={styles.buy_btn}
                                        type="submit"
                                        disabled={!isValid}
                                    // onClick={(values) => { validateForm().then(values => { console.log("OnClick values", values) }) }}
                                    >
                                        Оплатить
                                    </button>
                                }

                            </div>
                        </Form>
                    );
                }}
            </Formik>
            <ShowForm />
        </div >
    );
};


const ListItem = ({ data, onClick }) => {

    // const listItem = useRef();

    return (
        <div
            // ref={listItem}
            key={data + Math.random()}
            data={data}
            className={styles.address_list__item}
            onClick={(e) => {
                onClick(e, data)
            }}
        >
            {data}
        </div>
    );
};


export default observer(PaymentForm);