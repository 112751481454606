import { makeAutoObservable } from "mobx";

const editorTabs = ["editor", "details"]

class editorStore {
    editorTab = 'editor';
    // "editor", "details"

    editableItem = {}

    constructor() {
        makeAutoObservable(this);
    }

    setEditorTab(editorTab) {
        this.editorTab = editorTab;
    }

    toggleEditorTab() {
        if (this.editorTab === "editor") {
            this.editorTab = "details";
        } else if (this.editorTab === "details") {
            this.editorTab = "editor";
        }
    }

    setEditableItem(editableItem) {
        this.editableItem = JSON.parse(JSON.stringify(editableItem));
    }

}

export default new editorStore()