import React from "react";
import "./Status.scss";

export default function Status({ message }) {
  return (
    <div className="full-screen">
      <div className="full-screen-content">
        <div className="loader-spinner"></div>
        {message && <div className="loader-status">{message}</div>}
      </div>
    </div>
  );
}
