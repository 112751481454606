import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import MenuItem from "../MenuItem";

import { useInView } from "react-intersection-observer";
import { inViewTimeout } from "../../scripts/inViewTimeout";

import GroupItem from "../GroupItem";
import classNames from "classnames";
import menuStore from "store/menuStore";

const ShowcaseItem = ({ swiper, allItems, currentItem }) => {
  const menuType = menuStore.menu.type;

  const { ref, inView, entry } = useInView({
    threshold: [0.001],
  });

  //перелистывает navMenu
  useEffect(() => {
    let scrollingElem = menuStore.showcaseItemsRef?.current;
    if (!scrollingElem) return;

    const onScroll = () => inViewTimeout(currentItem, swiper, entry);

    scrollingElem.addEventListener("scroll", onScroll);
    return () => scrollingElem.removeEventListener("scroll", onScroll);
  }, [currentItem, entry, swiper]);

  return (
    <div className="container">
      <div className="group-title scrollTo" data-scrolltoid={currentItem.id || "null"} ref={ref}>
        {currentItem.name}
      </div>
      <div className={classNames("showcase-item", { "showcase-list_type3": [3, 4].includes(menuType) })}>
        {currentItem.id
          ? allItems
              .filter((z) => z.parent === currentItem.id)
              .sort((a, b) => a.type > b.type && 1)
              .sort((a, b) => a.index > b.index)
              .map((z) => {
                if (!z.id) return null;
                if (z.type === 1) return <MenuItem data={z} key={z.id} />;
                if (z.type === 2) return <GroupItem data={z} key={z.id} />;
                return null;
              })
          : /* если это группа "Другое" */
            allItems
              .filter((z) => z.parent === currentItem.id /* && z.type === 1 */)
              // .sort((a, b) => a.type > b.type && 1)
              .sort((a, b) => a.index > b.index)
              .map((z) => {
                if (z.type === 1) return <MenuItem data={z} key={z.id} />;
                return null;
              })}
      </div>
    </div>
  );
};

export default observer(ShowcaseItem);
