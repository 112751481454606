import React from 'react';
import { useNavigate } from "react-router-dom";

import classnames from 'classnames';

import styles from '../ItemEditor/ItemEditor.module.scss';

import item_placeholder from '../../img/item_placeholder.svg';
import left_arrow_square_icon from '../../img/left_arrow_square_icon.svg';

import notFound_img from '../../img/travolta.svg';

const ShowcaseNotFound = () => {
    const data = {
        name: "Страница не найдена",
        description: "Что-то пошло не так, вернитесь назад",
        sizes: [{ id: 0, name: "4" }, { id: 1, name: "0" }, { id: 2, name: "4" },],
        modifiers: [],
        images: []
    }

    const menuId = localStorage.getItem("menuId")
    const tableId = localStorage.getItem('tableId')
    const tableNumber = localStorage.getItem('tableNumber')


    const navigate = useNavigate();

    // /showcase/f18aef3e-f239-45bc-94d1-63970ccb293f/?tableId=3bb44c02-821c-4e9f-bbd6-ec340293beff&tableNumber=4

    const { images, name, description, sizes, currency } = data

    const cards = [{ id: 0, name: "Тут" }, { id: 1, name: "Ничего" }, { id: 2, name: "Нет" },]

    const backRedirectHandler = () => {
        navigate(`/showcase/${menuId}/?tableId=${tableId}&tableNumber=${tableNumber}`)
    }

    return (
        <div>
            <div>
                <div div className={styles.editor_wrapper}>
                    <div className={styles.editor}>

                        <div className={styles.img_container} /* style={{ backgroundImage: `url(${images?.[0]})`}}  */ >
                            <img className={styles.notFound_img} src={notFound_img} alt="" />
                        </div>

                        <div className={styles.right_wrapper}>

                            <div className={styles.content_container}>

                                <div className={styles.header_wrapper}>
                                    <div className={styles.title_wrapper}>
                                        <div className={styles.title}>
                                            {name}
                                        </div>
                                    </div>

                                    <div className={styles.description}>
                                        {description}
                                    </div>

                                </div>

                                <div className={styles.body_wrapper}>

                                    <div className={styles.group_modifier_title}>
                                        Ошибка
                                    </div>
                                    <div className={styles.radio} id={"size"} role="group">

                                        {sizes?.map(item => {
                                            return (
                                                <div className={styles.radio_item} key={item.id}>
                                                    <input type="radio" id={item.id} name="size" value={item.id} >
                                                    </input>
                                                    <label className={styles.radio_label} htmlFor={item.id} >{item.name}</label>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div className={styles.card_wrapper}>
                                        {cards.map(card => {
                                            const counterActive = card.amount ? styles.active : null;

                                            return (
                                                <div className={styles.card} key={card.id}>
                                                    <div className={styles.card_img_wrapper}>
                                                        {card?.images?.length ?
                                                            <img src={card.images?.[0]} alt="img" />
                                                            :
                                                            <img className={styles.img_placeholder} src={item_placeholder} alt="default_img" />
                                                        }
                                                    </div>
                                                    <div className={styles.card_description_wrapper}>
                                                        <div className={styles.text}>
                                                            {card?.name}
                                                        </div>
                                                        <div className={styles.price}>
                                                            {card.price} {currency}
                                                        </div>
                                                        <div className={`${styles.item_btn_wrapper} ${counterActive}`}>
                                                            <button type="button" className={styles.plus_cart_btn}>
                                                                +
                                                            </button>
                                                            <div className={styles.item_count}>
                                                                {card.amount ? card.amount : null}
                                                            </div>
                                                            <button type="button" className={styles.minus_cart_btn}>
                                                                -
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        )}


                                    </div>
                                    <div className={styles.white_space}></div>


                                    <div className={classnames(styles.btn_wrapper)}>
                                        <button type="button" className={styles.btn_buy} onClick={backRedirectHandler} >
                                           <img src={left_arrow_square_icon} alt="left_arrow" className={styles.left_arrow_icon}></img> Вернуться назад
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </div>
    );
};

export default ShowcaseNotFound;