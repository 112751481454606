import axios from "axios";

import ConfigurationManager from "../config";

// import PopupStore from "../store/PopupStore";

export default class ConnectionManager {
  static _instance;
  api;

  constructor() {
    this.api = axios.create({
      withCredentials: false,
      baseURL: ConfigurationManager.GetInstance().getItem("API_BASE"),
    });

    this.api.interceptors.request.use((config) => {
      if (!config.headers) config.headers = {};

      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${localStorage.getItem("userId")}`,
      };

      return config;
    });
  }

  GetClient() {
    return this.api;
  }

  static GetInstance() {
    return this._instance ?? (this._instance = new ConnectionManager());
  }
}