import React from 'react';
import menuStore from 'store/menuStore';
import Addon from './Addon';

import styles from '../Cart.module.scss'

const AddonList = () => {
    return (
        <div className={styles.addon_list} >
            {menuStore.menu?.addons?.map(addon => <Addon key={addon.id} addon={addon} />)}
        </div>
    );
};

export default AddonList;