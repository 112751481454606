import React, { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toJS } from "mobx";
import { observer } from "mobx-react-lite";

import ShowForm from "../../components/forms/ShowForm";
import OrderList from "../../components/OrderList/OrderList";

import menuStore from "../../store/menuStore";
import orderStore from "../../store/orderStore";

import ConfigurationManager from "config";

import styles from "./OrderPage.module.scss";
import classNames from "classnames";

import { ReactComponent as FloppySvg } from "img/floppy_icon.svg";

const OrderPage = () => {
  const navigate = useNavigate();
  const { orderId } = useParams("orderId");

  const { sum, externalNumber, canPayExternal, status, whenCreated, creationStatus, errorInfo, id } = orderStore.orderInfo;

  const prepareItems = useCallback((items) => {
    return items.map((item) => {
      const menuItem = menuStore.findProductInMenu(item.id, "item");
      if (!menuItem) return item;
      return { ...item, name: menuItem.name, images: menuItem.Images };
    });
  }, []);

  let items = orderStore.orderInfo.items;
  const menuModifierItems = menuStore.menu.items.filter((item) => item.type === 3);

  console.log("menuModifierItems", menuModifierItems);
  const goBack = () => {
    const menuId = localStorage.getItem("menuId");
    const tableId = localStorage.getItem("tableId");
    const tableNumber = localStorage.getItem("tableNumber");
    navigate(`/showcase/${menuId}/?tableId=${tableId}&tableNumber=${tableNumber}`);
  };
 
  const paymentSystem_URL =   ConfigurationManager.GetInstance().getItem("API_PAY")
  const goToPaymentSystem = () => (window.location.href = `${paymentSystem_URL}/Orders/${orderId}`);

  useEffect(() => {
    menuStore.setShowForm({ type: "orderLoading" });
    orderStore.setOrderInfo({});

    const fetchOrderData = async () => {
      await menuStore.fetchMenu(localStorage.getItem("menuId"));
      return await orderStore.getOrderInfo(orderId);
    };

    fetchOrderData()
      .then((data) => {
        if ([3, 4].includes(menuStore.menu?.integrationType)) {
          const items = prepareItems(data.items);
          data = { ...data, items };
        }
        orderStore.setOrderInfo(data);
      })
      .finally(() => menuStore.setShowForm({ type: "" }));
  }, [orderId, prepareItems]);

  console.debug("orderStore.orderInfo", toJS(orderStore.orderInfo));

  // !!! проверять статус оплаты
  // orderStore.orderInfo.status: "Created"

  const statusMessage = (status) => {
    const statuses = {
      // Статусы заказа:
      // Заказы доставка транспорт
      Unconfirmed: "Не подтвержден",
      WaitCooking: "Ожидает приготовления",
      ReadyForCooking: "Отправлен на кухню",
      CookingStarted: "Готовится",
      CookingCompleted: "Приготовлен",
      Waiting: "Ожидает",
      OnWay: "В пути",
      Delivered: "Доставлен",
      Cancelled: "Отменен",
      //"Closed": "Закрыт",

      // Заказы на столах транспорт
      New: "Новый",
      Bill: "Cчет",
      //"Closed":  "Закрыт",
      //"Deleted":  "Удален",

      // Заказы Биз
      Новая: "Новая",
      "Ждет отправки": "Ждет отправки",
      "Awaiting delivery": "Ждет отправки",
      "В пути": "В пути",
      "On the way": "В пути",
      Закрыта: "Закрыта",
      Отменена: "Отменена",
      Доставлена: "Доставлена",
      "Не подтверждена": "Не подтверждена",
      "Not confirmed": "Not confirmed",
      Готовится: "Готовится",
      "In progress": "Готовится",
      Готово: "Готово",
      Ready: "Готово",
      //"Closed":  "Закрыта",
      //"Cancelled":  "Отменена",
      //"New":  "Новая",
      //"Delivered":  "Доставлена ",

      //RMS
      NEW: "Новый",
      PROCESSED: "Принят в обработку",
      DELETED: "Удален",
    };

    return statuses[status] ?? "";
  };

  const creationStatusMessage = (status) => {
    switch (status) {
      case "Paid":
        return "Оплачено";
      case "Created":
        return "Создан";
      case "Pushed":
        return "В ожидании";
      case "Closed":
        return "Закрыт";
      case "Deleted":
        return "Удален";
      case "Error":
        return "Ошибка";
      default:
        break;
    }
  };

  const date = new Date(whenCreated);
  const dateString = `${date.getDay()}.${date.getMonth()}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;

  useEffect(() => {
    if(!status) {
      menuStore.setShowForm({type: "orderLoading"})
    }
  })

  return (
    <>
      <div className={styles.orderPage_wrapper}>
        <div className={styles.order_wrapper}>
          <div className={classNames(styles.order_wrapper_container, styles.save_btn_container)}>
            <div className={styles.order_title}>
              Заказ №{externalNumber} - {status && statusMessage(status)}
            </div>

            <SaveOrder />
          </div>

          <OrderList items={items} />
          <div className={styles.order_wrapper_container}>
            <div className={styles.order_details}>
              <div className={styles.order_details_inner}>Дата создания: {dateString}</div>
              <div className={styles.order_details_inner}>Сумма заказа: {sum} руб.</div>
            </div>

            <div className={styles.item_btn_wrapper}>
              <button className={styles.order_btn} type="button" onClick={goBack}>
                Вернуться в меню
              </button>
            </div>

            <div style={{ marginTop: "5px" }}>
              {orderStore.orderInfo &&
                canPayExternal &&
                (status !== "Closed" || status !== "Закрыта" || creationStatus === "Paid") && (
                  <button className={styles.order_btn} type="button" onClick={goToPaymentSystem}>
                    Оплатить
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
      <ShowForm />
    </>
  );
};

export default observer(OrderPage);

// import React from 'react';

const SaveOrder = () => {
  const [isOrderSaved, setIsOrderSaved] = useState(false);

  const saveOrder = () => {
    if (!isOrderSaved) {
      const menuId = localStorage.getItem("menuId");
      const localOrders = JSON.parse(localStorage.getItem("orders"));

      console.log("localOrders", localOrders);
      if (localOrders?.menuId && localOrders?.menuId === menuId) {
        localStorage.setItem(
          "orders",
          JSON.stringify({
            menuId: localOrders.menuId,
            orderInfoList: [...localOrders.orderInfoList, orderStore.orderInfo],
          })
        );
      } else {
        localStorage.setItem("orders", JSON.stringify({ menuId, orderInfoList: [orderStore.orderInfo] }));
      }

      setIsOrderSaved(true);
    }
  };
  return (
    <div className={classNames(styles.save_btn, { [styles.disabled]: isOrderSaved })} onClick={() => saveOrder()}>
      <FloppySvg />
    </div>
  );
};

// export default SaveOrder;
