import React from 'react';

// import styles from './Spinner.module.scss';

import './Spinner.scss';

const Spinner = ({ mode = "regular" }) => {
    console.log("spinner mode", mode)
    if (mode === "regular") return <div className={"lds_default"}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    if (mode === "small") return <div className="loader"></div>

    // return (
    //     <div className={"lds_default"}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    // );
};

export default Spinner;