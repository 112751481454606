import React from 'react';
import { observer } from 'mobx-react-lite';

import CartItem from './CartItem';

import cartStore from 'store/cartStore';

import styles from '../CartList.module.scss';

const CartList = () => {
    return (
        <div className={styles.item_list}>
            {cartStore.cart?.length !== 0 && cartStore.cart.map(data => {
                if (data === null) { sessionStorage.removeItem("cart"); return <div></div>} 
                return <CartItem data={data} key={data.cartId} />
            })}
        </div>
    );
};

export default observer(CartList);