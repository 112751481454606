import React from 'react';
import classNames from 'classnames';
import OrderItem from './OrderItem/OrderItem';

import styles from './OrderList.module.scss';

const OrderList = ({ items }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.item_list}>
                {items?.length > 0 &&
                    items.map(item => <OrderItem key={item.id + Math.random()} data={item} />)
                }
            </div>
            <div className={classNames(styles.border, styles.top)}>
                <div className={styles.border_line}></div>
            </div>
            <div className={classNames(styles.border, styles.bottom)}>
                <div className={styles.border_line}></div>
            </div>
        </div>
    );
};

export default OrderList;