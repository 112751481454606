import React, { createContext, useContext, useEffect, useState } from "react";
import { Route, BrowserRouter, Routes, useParams } from "react-router-dom";
import ConfigurationManager from "./config";

import { v4 as uuidv4 } from "uuid";

import OrderPage from "./pages/OrderPage/OrderPage";
import Showcase from "./pages/Showcase";

import PaymentForm from "./components/forms/PaymentForm";
import ShowcaseNotFound from "./components/ShowcaseNotFound";
import Status from "./components/Status";

import "./App.scss";
import NotWorkedTimePage from "pages/notWorketTime";

import SignalRConnectionManager from "./sinalR";
import orderStore from "store/orderStore";
import menuStore from "store/menuStore";

export const ConnectionContext = createContext();

function App() {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState();
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    const paramsString = document.location.search;
    const searchParams = new URLSearchParams(paramsString);
    const tableId = searchParams.get("tableId");

    const tableNumber = searchParams.get("tableNumber");
    if (tableId) {
      if (tableId !== localStorage.getItem("tableId")) localStorage.removeItem("orderId"); //!!! внимание, если мы заходим с другой меню, то удаляем id старого заказа
      localStorage.setItem("tableId", tableId);
      localStorage.setItem("tableNumber", tableNumber);
    }
  }, []);

  useEffect(() => {
    if (!isInitialized) {
      ConfigurationManager.GetInstance()
        .fetch()
        .then(() => {
          //signal R init
          const newConnection = SignalRConnectionManager.GetInstance().GetClient();
          setConnection(newConnection);
        })
        .then(() => setIsInitialized(true))
        .catch(() => setError("Ошибка загрузки конфигурации"));
    }
  }, [isInitialized]);

  useEffect(() => {
    if (!localStorage.getItem("userId")) {
      localStorage.setItem("userId", uuidv4());
    }
  }, []);

  /* signal R handlers */

  useEffect(() => {
    //get memuId from href using RegExp
    const regExMenuId = /\/showcase\/(?<menuId>[\s\S]+?)\//g;
    const href = document.location.href;
    const matches = href.matchAll(regExMenuId);
    const menuId = [...matches]?.[0]?.groups?.menuId;

    const orderId = localStorage.getItem("orderId");

    if (connection?._connectionState === "Disconnected" || connection?._connectionStarted === false) {
      connection
        .start()
        .then((result) => {
          console.log("connection START");

          connection.on("handshake", (res) => {
            console.log('connection.on handshake"', res);
          });

          if (orderId) {
            connection.invoke("SetOrderId", localStorage.getItem("orderId")).then((res) => console.log("invoke setOrderId", res));
          }

          if (menuId) {
            connection.invoke("SetShowcaseId", menuId).then((res) => console.log("connection.invoke SetShowcaseId", res));
          }
        })
        .then(() => {
          connection.on("OrderUpd", (res) => {
            console.log("connection.on OrderUpd", res);
            orderStore.setOrderInfo(res);
          });

          connection.on("ShowcaseUpd", (res) => {
            console.log("connection.on ShowcaseUpd", res);
            menuStore.setMenu(res);
          });
        })
        .catch((e) => console.error("SignalR Connection failed: ", e));
    }
  }, [connection]);

  /*  */

  return !isInitialized || error ? (
    <Status message={error ?? "Загрузка конфигурации"} />
  ) : (
    <ConnectionContext.Provider value={{ connection }}>
      <BrowserRouter>
        <Routes>
          <Route path="/showcase/:id/*" element={<Showcase />}>
            <Route path="group/:parent" element={<Showcase />} />
          </Route>
          <Route path="/showcase/:id/order/:orderId" element={<OrderPage />} /> {/* матчится */}
          <Route path="/order/:orderId" element={<OrderPage />} /> {/* матчится */}
          <Route path="/showcase/:id/pay" element={<PaymentForm />} />
          <Route path="/showcase/:id/notWorkedTime" element={<NotWorkedTimePage />} />
          <Route path="*" element={<ShowcaseNotFound />} />
        </Routes>
      </BrowserRouter>
    </ConnectionContext.Provider>
  );
}

export default App;
