import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import classnames from 'classnames';

import editorStore from '../../store/editorStore';
import CardInEditor from './CardInEditor';

import item_placeholder from '../../img/item_placeholder.svg';

import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from "yup";


import styles from "./ItemEditor.module.scss";
import menuStore from '../../store/menuStore';
import { set, toJS } from 'mobx';
import cartStore from '../../store/cartStore';
import { useEffect } from 'react';


import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import "./ItemEditor_swiper.scss";

const ItemEditor = (
    { data = editorStore.editableItem }
) => {
    const navigate = useNavigate();

    const [modifiersState, setModifiersState] = useState([])

    const [render, setRender] = useState({});

    const { productId } = useParams();

    const [editorTab, setEditorTab] = useState('editor');

    const toggleEditorTab = () => {
        if (editorTab === "editor") {
            setEditorTab("details");
        } else if (editorTab === "details" && (data.sizes?.length > 1 || data.modifiers?.length > 0 || data.groupModifiers?.length > 0)) {
            setEditorTab("editor");
        }
    }

    useEffect(() => {
        if (data.sizes?.length > 1 || data.modifiers?.length > 0 || data.groupModifiers?.length > 0) {
            setEditorTab("editor");
        } else {
            setEditorTab("details");
        }
    }, [data.groupModifiers?.length, data.modifiers?.length, data.sizes?.length])

    console.log("productId", productId)

    const {
        name,
        description,
        images,
        sizes,
        modifiers,
        currency,
        groupModifiers,
    } = data;

    useEffect(() => {
        console.log("data.groupModifiers", data.groupModifiers)
        if (data.groupModifiers?.length > 0 && data.groupModifiers?.[0].childModifiers?.length > 0) {
            for (let groupModifier of data.groupModifiers) {
                if (groupModifier.minAmount > 0) {
                    for (let i = 0; i < groupModifier.minAmount; i++) groupModifier.childModifiers[i].amount = 1;
                }
            }
        }

    }, [data.groupModifiers])

    console.log("ItemEditor render tab", editorTab)

    return (
        <>
            <Formik
                initialValues={{
                    ...data,
                    size: sizes?.length > 0 ? sizes?.[0]?.id : '',
                    groupModifiers: groupModifiers ? groupModifiers : [],
                    totalPrice: data.price,
                }}
                onSubmit={async (values, actions) => {
                    // alert(JSON.stringify(values))
                    if (values.modifiers?.length > 0) values.modifiers = values?.modifiers?.filter(mod => mod.amount > 0)
                    cartStore.pushItemToCart(values);
                    // navigate(-1);
                    menuStore.setShowForm({ type: '' });
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().trim().email('Введите корректный email'),
                })}
            >

                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        // isSubmitting,
                        isValid,
                        handleChange,
                        // handleBlur,
                        // handleSubmit,
                        // handleReset
                    } = props;

                    console.debug("item editor form values", toJS(values))

                    const onCardIncrement = (e, id) => {
                        if (values.modifiers) console.debug("onCardIncrement", toJS(values.modifiers))

                        const res = values.modifiers?.map(modifier => {
                            if (id !== modifier.id) return modifier;

                            if (!modifier.amount) modifier.amount = 0
                            if (modifier.amount === modifier.maxAmount && modifier.maxAmount !== 0) return modifier
                            modifier.amount++
                            return modifier;
                        })

                        setModifiersState(res);
                    }

                    const onCardDecrement = (e, id) => {
                        if (values.modifiers) console.debug("onCardDecrement", toJS(values.modifiers))

                        const res = values.modifiers.map(modifier => {
                            if (id !== modifier.id) return modifier;

                            if (!modifier.amount) modifier.amount = 0;
                            if (modifier.amount === modifier.minAmount && modifier.maxAmount !== 0) return modifier
                            modifier.amount = modifier.amount - 1;
                            return modifier;
                        })

                        setModifiersState(res);
                    }

                    const childModifierOnClickHandler = (e, childModifier, groupModifier) => {

                        // ищем childModifier среди values и присваеваем ему amount
                        const childModifiersSum = groupModifier.childModifiers.reduce((a, b) => b.amount ? a + b.amount : a, 0)
                        if (groupModifier.maxAmount < childModifiersSum || groupModifier.minAmount > childModifiersSum) return

                        if (groupModifier.maxAmount === 1 && groupModifier.minAmount === 1) {
                            for (let child of groupModifier.childModifiers) child.amount = 0;
                            childModifier.amount = 1; return;
                        }

                        if (groupModifier.maxAmount === 1 && groupModifier.minAmount === 0) {
                            if (!childModifier.amount) {
                                for (let child of groupModifier.childModifiers) child.amount = 0;
                                childModifier.amount = 1; return;
                            } else {
                                childModifier.amount = 0; return;
                            }
                        }

                        if (groupModifier.maxAmount <= childModifiersSum) {
                            if (childModifier.amount) childModifier.amount--; return;
                        }

                        if (!childModifier.amount) childModifier.amount = 1; return;
                    }

                    const calcTotalPrice = () => {

                        let startPrice = data.price;
                        let totalPrice = 0;
                        totalPrice = startPrice;

                        if (values?.sizes?.length > 0) {
                            console.log("values.sizes[0].price", values.sizes[0].price)
                            totalPrice = values.sizes[0].price
                            for (let size of values.sizes) {
                                if (size.id === values.size) {
                                    totalPrice = size.price
                                }
                            }
                        }

                        if (values.modifiers?.length > 0) {
                            for (let modifier of values.modifiers) {
                                if (modifier.amount > 0) totalPrice += modifier.price * modifier.amount
                            }
                        }

                        for (let groupModifier of values.groupModifiers) {
                            for (let modifier of groupModifier.childModifiers) {
                                if (modifier.amount > 0) totalPrice += modifier.price * modifier.amount
                            }
                        }

                        values.totalPrice = totalPrice;

                        return totalPrice
                    }

                    return (

                        <Form className={styles.editor_wrapper}>

                            <div className={styles.editor}>


                                <button type="button" className={styles.back_btn} onClick={() => /* navigate(-1) */ menuStore.setShowForm({ type: '' })}>
                                    <i className="fas fa-times"></i>
                                </button>

                                <div className={styles.img_container} /* style={{ backgroundImage: `url(${images?.[0]})`}}  */ >
                                    <div className={styles.img_background} style={{ backgroundImage: `url(${images?.[0]})` }}  >
                                    </div>
                                    {images?.length > 0 ?
                                        <img src={images?.[0]} alt="" />
                                        :
                                        <img className={styles.placeholder} src={item_placeholder} alt="" />
                                    }
                                </div>

                                <div className={styles.right_wrapper}>

                                    <div className={styles.content_container}>

                                        <div className={styles.header_wrapper}>
                                            <div className={styles.title_wrapper}>
                                                <div className={styles.title}>
                                                    {name}
                                                    {(data.sizes?.length > 1 || data.modifiers?.length > 0 || data.groupModifiers?.length > 0) &&
                                                        <div className={styles.btn_info} onClick={() => { /* editorStore. */ toggleEditorTab(); setRender({}) }}>
                                                            <i className="fas fa-info-circle"></i>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            {/* editorStore. */ editorTab === "editor" &&
                                                <div className={styles.description}>
                                                    {description?.length > 80 ? description.slice(0, 80).concat("...") : description}
                                                </div>
                                            }

                                            {/* editorStore. */ editorTab === "details" &&
                                                <div className={styles.description}>
                                                    {description}
                                                </div>
                                            }
                                        </div>

                                        <div className={styles.body_wrapper}>
                                            {/* editorStore. */ editorTab === "editor" &&
                                                <>
                                                    {sizes?.length > 0 &&
                                                        <>
                                                            <div className={styles.group_modifier_title}>
                                                                Размеры
                                                            </div>
                                                            <div className={styles.radio} id={"size"} role="group">

                                                                {sizes?.map(item => {
                                                                    return (
                                                                        <div className={styles.radio_item} key={item.id}>
                                                                            <Field type="radio" id={item.id} name="size" value={item.id} >
                                                                            </Field>
                                                                            <label className={styles.radio_label} htmlFor={item.id} >{item.name}</label>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </>
                                                    }

                                                    {values.groupModifiers?.length > 0 &&
                                                        < FieldArray name="groupModifiers" render={() => values.groupModifiers
                                                            .map((groupModifier, i) => {

                                                                if (groupModifier.maxAmount /* === 1 */) {
                                                                    return (
                                                                        <div className={styles.group_modifier} key={groupModifier.id}>

                                                                            <div className={styles.group_modifier_title}>
                                                                                {menuStore.menu?.items.filter(elem => elem.id === groupModifier.id)?.[0]?.name} {/* groupModifier.minAmount > 0 &&  *//* `${groupModifier.minAmount}/${groupModifier.maxAmount}` */}
                                                                            </div>
                                                                            <div className={styles.group_modifier_buttons} id={`groupModifiers.${i}.childModifiers`} role="group">
                                                                                <Swiper
                                                                                    slidesPerView={'auto'}
                                                                                    spaceBetween={0}
                                                                                    className="ItemEditor_swiper"
                                                                                >
                                                                                    {groupModifier.childModifiers.map((childModifier, n) => {
                                                                                        return (
                                                                                            <SwiperSlide key={childModifier.id}>
                                                                                                <div
                                                                                                    className={classnames(styles.group_modifier_item, childModifier.amount ? styles.active : null)}
                                                                                                    type="button"
                                                                                                    key={childModifier.id}
                                                                                                    data-childmodifierid={childModifier.id}
                                                                                                    onClick={(e) => { childModifierOnClickHandler(e, childModifier, groupModifier); setRender({}) }}
                                                                                                >
                                                                                                    {menuStore.menu?.items.filter(elem => elem.id === childModifier.id)?.[0]?.name}
                                                                                                </div>
                                                                                            </SwiperSlide>
                                                                                        )
                                                                                    })}
                                                                                </Swiper>

                                                                            </div>
                                                                        </div>

                                                                    )

                                                                }
                                                                /* вариант, если групповыз модификаторов может быть amount > 1 */
                                                                if (/* groupModifier.maxAmount > 1 */ false) {
                                                                    return (
                                                                        /* сделать радио */
                                                                        <div key={groupModifier.id} >
                                                                            <div className={styles.group_modifier}>
                                                                                {values.groupModifiers.length > 0 &&
                                                                                    < FieldArray name={`groupModifiers.${i}.childModifiers`} render={() => values.groupModifiers[i]?.childModifiers
                                                                                        ?.map((item, n) => {
                                                                                            const amount = groupModifiers[i].childModifiers[n].amount;
                                                                                            if (!amount) groupModifiers[i].childModifiers[n].amount = 0
                                                                                            return (
                                                                                                <div className={styles.group_modifier_item} key={item.id}>
                                                                                                    <button className={styles.add_modifier_btn} type="button"
                                                                                                        onClick={() => {
                                                                                                            if (amount <= 0) return
                                                                                                            let sumAmount = groupModifiers[i].childModifiers.reduce((a, b) => a + b.amount, 0)
                                                                                                            if (sumAmount >= groupModifiers[i].minAmount) groupModifiers[i].childModifiers[n].amount -= 1; setRender({});
                                                                                                        }} >
                                                                                                        -
                                                                                                    </button>

                                                                                                    <div className={styles.modifier_title}>
                                                                                                        {menuStore.menu.items
                                                                                                            .filter(menuItem => menuItem.id === item.id)
                                                                                                            .map(item => {
                                                                                                                return (
                                                                                                                    <div key={item.id} className={styles.modifier_content}>
                                                                                                                        <div value={item.id}>{item.name}</div>
                                                                                                                        <div className={styles.modifier_amount}>{amount}</div>
                                                                                                                    </div>)
                                                                                                            })}
                                                                                                    </div>
                                                                                                    <button className={styles.add_modifier_btn} type="button"
                                                                                                        onClick={() => {
                                                                                                            let sumAmount = groupModifiers[i].childModifiers.reduce((a, b) => a + b.amount, 0)
                                                                                                            if (sumAmount < groupModifiers[i].maxAmount) groupModifiers[i].childModifiers[n].amount += 1; setRender({});
                                                                                                        }} >
                                                                                                        +
                                                                                                    </button>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        )} />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                            })} />
                                                    }

                                                    {/* <div className={styles.card_title}> Добавить в товар </div> */}
                                                    <div className={styles.card_wrapper}>

                                                        <FieldArray
                                                            name="modifiers"
                                                            render={() => values.modifiers
                                                                ?.map(card => <CardInEditor
                                                                    key={card.id}
                                                                    card={card}
                                                                    currency={currency}
                                                                    onCardIncrement={onCardIncrement}
                                                                    onCardDecrement={onCardDecrement}
                                                                />)
                                                            }
                                                        />

                                                    </div>
                                                    <div className={styles.white_space}></div>
                                                </>
                                            }

                                            {/* editorStore. */ editorTab === "details" &&
                                                // weight: 0

                                                // energyAmount: 0
                                                // energyFullAmount: 0                                ​

                                                // fatAmount: 0 ​
                                                // fatFullAmount: 0

                                                // proteinsAmount: 0
                                                // proteinsFullAmount: 0

                                                // carbohydratesAmount: 0
                                                // carbohydratesFullAmount: 0
                                                <>
                                                    {data.energyFullAmount ? <div className={styles.description}>Энерг. ценность: {data.energyFullAmount}</div> : null}
                                                    {data.weight ? <div className={styles.description}>Вес: {data.weight}</div> : null}
                                                    {data.fatFullAmount ? <div className={styles.description}>Жиры: {data.fatFullAmount}</div> : null}
                                                    {data.proteinsFullAmount ? <div className={styles.description}>Белки: {data.proteinsFullAmount}</div> : null}
                                                    {data.carbohydratesFullAmount ? <div className={styles.description}>Углеводы: {data.carbohydratesFullAmount}</div> : null}
                                                    <div className={styles.white_space}></div>
                                                </>
                                            }
                                            {menuStore.menu?.isShowCart &&
                                                <div className={classnames(styles.btn_wrapper, styles.desktop)}>
                                                    <button type="submit" className={styles.btn_buy}>
                                                        Добавить в корзину - {calcTotalPrice()} {data.currency}
                                                    </button>
                                                </div>
                                            }
                                        </div>


                                    </div>
                                </div>
                                {menuStore.menu?.isShowCart &&
                                    <div className={classnames(styles.btn_wrapper, styles.mobile)}>
                                        <button type="submit" className={styles.btn_buy}>
                                            Добавить в корзину - {calcTotalPrice()} {data.currency}
                                        </button>
                                    </div>
                                }
                            </div>

                        </Form>)
                }}
            </Formik >
        </>
    );
};

export default observer(ItemEditor);