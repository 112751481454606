import React from 'react';
import { observer } from 'mobx-react-lite';
import { Formik, Form } from 'formik';

import styles from '../forms.module.scss';

import orderStore from 'store/orderStore';

import 'react-phone-input-2/lib/style.css'
import CustomInput from '../formComponents/CustomInput';
import menuStore from 'store/menuStore';


const CommentPopup = () => {

    const closeFormHandler = () => {
        menuStore.setShowForm({ type: '' })
    }

    return (
        <div className={styles.form_container}>
            <Formik
                initialValues={{ comment: orderStore.comment }}
                onSubmit={async (values, actions) => {
                    orderStore.setOrderComment(values.comment)
                    closeFormHandler();
                    // console.log("onSubmit pay form", values.comment);
                    // payStore.postPaymentDataOnServer({ ...data, paymentType })
                }}
            >

                {props => {
                    const {
                        values,
                        errors,
                    } = props;

                    console.log("form values", values)
                    // console.log(props)

                    return (
                        <Form className={styles.form}                            >
                            <div className={styles.close_btn} onClick={() => closeFormHandler()}>
                                <i className="fas fa-times"></i>
                            </div>
                            <div className={styles.form_title}>
                                Комментарий к заказу
                            </div>

                            {<CustomInput as={"textarea"} name="comment" placeholder={"Введите комментарий"} />}

                            <div className={styles.btn_wrapper}>

                                <button
                                    className={styles.buy_btn}
                                    type="submit"
                                >
                                    Принять
                                </button>

                            </div>
                        </Form>
                    );
                }}
            </Formik >
            {/* <ShowForm /> */}
        </div >
    );
};


export default observer(CommentPopup);