import React from 'react';
import classNames from 'classnames';
import item_placeholder from '../../../img/item_placeholder.svg';
import menuStore from '../../../store/menuStore';

import styles from '../ItemEditor.module.scss';

const CardInEditor = ({
    card,
    currency,
    onCardDecrement,
    onCardIncrement,
}) => {

    if (!card.amount) card.amount = 0;
    if (card.amount > card.maxAmount && card.maxAmount !== 0) card.amount = card.maxAmount;
    if (card.amount < card.minAmount) card.amount = card.minAmount;

    const cardData = menuStore.menu.items.filter(item => item.id === card.id)?.[0]

    return (
        <div className={styles.card} key={card.id}>
            <div className={styles.card_img_wrapper}>
                {cardData?.images?.length ?
                    <img src={cardData.images?.[0]} alt="img" />
                    :
                    <img className={styles.img_placeholder} src={item_placeholder} alt="default_img" />
                }
            </div>
            <div className={styles.card_description_wrapper}>
                <div className={styles.text}>
                    {cardData?.name}
                </div>
                <div className={styles.price}>
                    {card.price} {currency}
                </div>
                <div className={`${styles.item_btn_wrapper}`}>
                    {menuStore.menu?.isShowCart && 
                        <>
                            <button
                                className={classNames(styles.plus_cart_btn, { [styles.active]: card.amount !== card.maxAmount })}
                                type="button"
                                onClick={(e) => onCardIncrement(e, card.id)}
                            >
                                +
                            </button>
                            <div className={styles.item_count}>
                                {card.amount ? card.amount : null}
                            </div>
                            <button
                                className={classNames(styles.minus_cart_btn, { [styles.active]: !!card.amount && card.amount !== card.minAmount })}
                                type="button"
                                onClick={(e) => onCardDecrement(e, card.id)}
                            >
                                -
                            </button>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default CardInEditor;