import React from 'react';
import classNames from 'classnames';
import menuStore from 'store/menuStore';

import styles from './UpsPopup.module.scss';

const UpsPopup = ({message, status}) => {
    // status - error, ok, warning
    if (!message) message = "Что-то пошло не так"
    return (
        <div className={styles.loader_container} onClick={() => {menuStore.setShowForm({type: ''});}}>
            <div className={classNames(styles.loader, {[styles.error]: status === "error"})}>
                <div className={styles.loader_title}>
                    {message}
                </div>
            </div>
        </div>
    );
};

export default UpsPopup;

// подгоняем официанта
// будим шеф повара
