import React, { useState } from 'react';
import { useEffect } from 'react';

import Spinner from './Spinner';

import styles from './LoadingPopup.module.scss';

const LoadingPopup = () => {

    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const [message, setMessage] = useState('')

    useEffect(() => {
        const messages = ["Обрабатываем заказ", "Подгоняем официанта", "Будим шеф повара"]
        const interval = setInterval(() => {
            setMessage(messages[randomIntFromInterval(0, 2)])
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className={styles.loader_container}>
            <div className={styles.loader}>
                <Spinner />
                <div className={styles.loader_title}>
                    {message ? message : "Заказываем продукты"}...
                    
                </div>
            </div>
        </div>
    );
};

export default LoadingPopup;
