import classNames from 'classnames';
import React from 'react';

import styles from '../Cart.module.scss';

const CartSwitcher = ({ value, onChange }) => {
    return (
        <div className={styles.switcher_wrapper}>
            <div className={classNames(styles.switcher, value ? styles.left : styles.right)}>
                <button className={classNames(styles.switcher_btn, { [styles.active]: value })} onClick={() => onChange(true)}>
                    Корзина
                </button>
                <button className={classNames(styles.switcher_btn, { [styles.active]: !value })} onClick={() => onChange(false)}>
                    Ваш заказ
                </button>
            </div>
        </div>
    );
};

export default CartSwitcher;