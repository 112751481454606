import React from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import cartStore from '../../store/cartStore';
import menuStore from '../../store/menuStore';
import styles from './TopBar.module.scss';

import defaultLogo from '../../img/logo.svg';
import cart_icon from "img/cart_icon.svg"
import SearchBar from './SearchBar/SearchBar';

const TopBar = () => {

    const banner = menuStore?.menu?.banner || '';

    const onToggleMenuHandler = () => {
        cartStore.toggleIsCartOpen();
        menuStore.setIsNavMenuOpen(false);
    }

    return (
        <>
            <div className={`${styles.nav_menu_wrapper} ${!menuStore.isNavMenuOpen ? styles.active : null}`} data-scroll='false'>
                <div className={`container ${styles.nav_menu}`}>
                    <div className={styles.menu_title_wrapper}>
                        {menuStore?.menu?.banner ?
                          <div className={styles.menu_logo}>
                              <img src={banner} alt="foodof_logo" />
                          </div>
                          :
                          <div className={`${styles.menu_logo} ${styles.menu_logo_default}`}>
                              <img src={defaultLogo} alt="foodof_logo" />
                          </div>
                        }

                        <div className={styles.menu_title}>
                            {menuStore.menu?.name}
                        </div>
                    </div>

                    <div className={styles.btn_wrapper}>
                        <SearchBar />

                        <div className={styles.nav_menu_btn}
                            onClick={onToggleMenuHandler}>

                            {menuStore.menu.isShowCart !== false &&
                                <>
                                    {cartStore.totalCartCounter ?
                                        <div className={styles.nav_menu_btn_counter}>
                                            {cartStore.totalCartCounter}
                                        </div>
                                        : null}

                                    <img src={cart_icon} alt="cart_icon" />
                                </>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </>

    );
};

export default observer(TopBar);