import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import classNames from 'classnames';

import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';

import { useSwipeable } from 'react-swipeable';

import cartStore from '../../store/cartStore';
import menuStore from '../../store/menuStore';
import orderStore from '../../store/orderStore';
import payStore from '../../store/payStore';

import { ConnectionContext } from 'App';

import CartList from './CartList/CartList';
import TotalPriceCartHeader from './cartComponents/TotalPriceCartHeader';
import CartSwitcher from './cartComponents/CartSwitcher';
import AddonList from './Addon/AddonList';
import OrderListInCart from './OrderListInCart/OrderListInCart';

import shevron_rigth_icon from '../../img/shevron_right_icon.svg';
import buy_btn_icon from '../../img/buy_btn_icon.svg';
import waiter_alert_icon from '../../img/waiter_alert_icon.svg';

import styles from "./Cart.module.scss";
import InnInput from './cartComponents/InnInput';
import CartDate from './cartComponents/CartDate';
import OrderSelectBox from './cartComponents/OrderSelectBox';

const Cart = () => {
    const [cartSwitcher, setCartSwitcher] = useState(true);
    //true - cart, false - order

    const navigate = useNavigate();

    //signalR
    //=========================================

    const { connection } = useContext(ConnectionContext);

    const sendOrderId = async (orderId) => {

        console.log("connection", connection);
        if (connection._connectionStarted) {
            try {
                console.log("connection SetOrderId", orderId, connection)
                await connection.invoke("SetOrderId", orderId);
                // await connection.invoke("SendMessage", orderId);
                // await connection.send('OrderUpd', orderId);
            }
            catch (e) {
                console.log("connection.error", e);
            }
        }
        else {
            alert('No connection to server yet.');
        }
    }

    //=========================================
    const menuType = menuStore.menu.type;

    const onGetOrderHandler = async () => {
        sessionStorage.setItem("cart", JSON.stringify(cartStore.cart))
        localStorage.setItem("contragentInn", orderStore.contragentInn)

        if ([3, 4].includes(menuType)) {
            console.log("[3, 4].includes(menuType)", orderStore.contragentInn?.length < 10, orderStore.activeContragent?.id)
            if (orderStore.contragentInn?.length < 10) {
                menuStore.setShowForm({ type: 'error', payload: 'ИНН меньше 10 символов' });
                return
            }
            if (!orderStore.activeContragent?.id) {
                menuStore.setShowForm({ type: 'error', payload: 'Выберите контрагента из списка' });
                return
            }
        }

        menuStore.setShowForm({ type: 'orderLoading' });
        console.debug("cartStore.cart", toJS(cartStore.cart))

        // localStorage.setItem('orderId', JSON.stringify(orderStore.orderInfo));

        // в зависимости от типа работы меню
        switch (menuType) {
            case 0: break; //витрина
            case 1:        //table сервис
                orderStore.checkingOrderStatus().then(() => {
                    if (orderStore.orderInfo?.creationStatus === "Pushed") {
                        setCartSwitcher(false);
                        localStorage.setItem('orderId', orderStore.orderInfo.id);
                        sendOrderId(orderStore.orderInfo.id);
                        cartStore.setCart([]);
                    }
                })
                    .then(() => menuStore.setShowForm({ type: '' }))
                    .catch(error => {
                        console.log("checkingOrderStatus in cart", error)
                        if (error.creationStatus === "Error") {
                            menuStore.setShowForm({ type: 'error' });
                        }
                    })
                break;
            case 2: navigate(`/showcase/${menuStore.menu.id}/pay`); break; // доставка
            case 3: //RMS расход
                console.log("onGetOrderHandler case 3:")
                orderStore.checkingOrderStatus()
                    .then(() => {
                        if (orderStore.orderInfo?.creationStatus === "Pushed") {

                            localStorage.setItem('orderId', orderStore.orderInfo.id);
                            navigate(`/showcase/${menuStore.menu.id}/order/${orderStore.orderInfo.id}`);
                            cartStore.setCart([]);
                        }
                        else {
                            throw new Error();
                        }
                    })
                    .then(() => menuStore.setShowForm({ type: 'messagePopup', payload: "Расходная накладная успешно отправлена" }))
                    .catch(error => {
                        console.log("checkingOrderStatus in cart", error)
                        menuStore.setShowForm({ type: 'error', payload: "Ошибка проверки статуса заказа" });
                    })
                break;
            case 4: //RMS приход
                console.log("onGetOrderHandler case 4:")

                orderStore.checkingOrderStatus()
                    .then((data) => {
                        if (orderStore.orderInfo?.creationStatus === "Pushed") {

                            localStorage.setItem('orderId', orderStore.orderInfo.id);
                            navigate(`/showcase/${menuStore.menu.id}/order/${orderStore.orderInfo.id}`);
                            cartStore.setCart([]);
                        } else {
                            throw new Error(data);
                        }
                    })
                    .then(() => menuStore.setShowForm({ type: 'messagePopup', payload: "Приходная накладная успешно отправлена" }))
                    .catch(error => {
                        console.log("checkingOrderStatus in cart", error)
                        menuStore.setShowForm({ type: 'error', payload: "Ошибка проверки статуса заказа" });
                    })
                break;
            default: break;
        }
    }

    const onToggleMenuHandler = () => {
        cartStore.toggleIsCartOpen();
        menuStore.setIsNavMenuOpen(false);
    }

    //Swipable
    const config = {
        delta: 10,                             // min distance(px) before a swipe starts. *See Notes*
        preventScrollOnSwipe: false,           // prevents scroll during swipe (*See Details*)
        trackTouch: true,                      // track touch input
        trackMouse: true,                      // track mouse input
        rotationAngle: 0,                      // set a rotation angle
        swipeDuration: Infinity,               // allowable duration of a swipe (ms). *See Notes*
        touchEventOptions: { passive: true },  // options for touch listeners (*See Details*)
    }

    const handlers = useSwipeable({
        // onSwiped: (eventData) => console.log("User onSwiped!", eventData), ...config,
        onSwipedRight: (eventData) => {
            cartStore.setIsCartOpen(false);
        }, ...config,
        onSwipedLeft: (eventData) => {
            cartStore.setIsCartOpen(true);
        }, ...config,
    });
    // /Swipable

    const goToPaymentSystem = async () => {
        menuStore.setShowForm({ type: 'orderLoading' })

        const paymentSystemPayload = {
            orderId: orderStore.orderInfo.id,
            payment: menuStore.menu.availablePayments.find(item => item.id)
        }

        await orderStore.changePaymentSystemInOrder(paymentSystemPayload)

        await payStore.createInvoice(orderStore.orderInfo.id)
            .then(data => {
                menuStore.setShowForm({ type: 'orderLoading' });
                window.location.href = data.link;
            })
    }

    useEffect(() => {
        const orderId = localStorage.getItem('orderId')
        orderStore.setOrderInfo({});

        // menuStore.fetchMenu(localStorage.getItem('menuId'));
        if (orderId) {
            menuStore.setShowForm({ type: 'orderLoading' })
            orderStore.getOrderInfo(orderId).then(data => orderStore.setOrderInfo(data)).then(() => menuStore.setShowForm({ type: '' }))
        }

    }, [])

    console.debug("cart orderStore.orderInfo", toJS(orderStore.orderInfo));
    console.debug("completeBefore", toJS(orderStore.completeBefore));
    console.debug("menuStore.menu.addons", menuStore.menu?.addons)

    return (
        <>
            <div className={classNames(styles.cart_screen_background, cartStore.isCartOpen ? null : styles.hide)} {...handlers}>
            </div>
            <div className={`${styles.cart_menu_wrapper} ${cartStore.isCartOpen ? styles.active : null}`} {...handlers}>
                <div className={classNames(styles.cart_menu_container, { [styles.cart_menu_container_inn]: [3, 4].includes(menuType) })}>

                    {[0, 1].includes(menuType) && orderStore.orderInfo?.items?.length > 0 &&
                        <CartSwitcher value={cartSwitcher} onChange={setCartSwitcher} />
                    }

                    <TotalPriceCartHeader hide={cartSwitcher} />

                    {[3, 4].includes(menuType) &&
                        <OrderSelectBox />
                    }

                    {[3, 4].includes(menuType) && menuStore.menu.canOrderByTime === true &&
                        <CartDate />
                    }

                    {[3, 4].includes(menuType) &&
                        <InnInput />
                    }
                </div>

                {cartSwitcher ?
                    <>

                        <div className={styles.cart_menu}>
                            <CartList />
                        </div>

                        <AddonList />

                        {[1, 2, 3, 4].includes(menuType) && cartStore.cart.length !== 0 &&
                            <div className={styles.buy_btn_wrapper} >

                                {menuStore.menu?.minimalOrderPrice > cartStore.totalCartPrice &&
                                    [3, 4].includes(menuStore.menu?.integrationType) ?

                                    <button className={classNames(styles.buy_btn, styles.disabled)}>
                                        Можно отправить заказ от {menuStore.menu.minimalOrderPrice} руб.
                                    </button>

                                    :

                                    <button className={styles.buy_btn} onClick={() => onGetOrderHandler()}>

                                        {[1].includes(menuType) ?
                                            "Добавить к заказу"
                                            :
                                            "Отправить заказ"
                                        }

                                        <img src={buy_btn_icon} alt="" />

                                    </button>
                                }

                            </div>
                        }
                    </>
                    :
                    <>
                        <div className={styles.cart_menu}>
                            <OrderListInCart items={orderStore.orderInfo.items} />
                        </div>
                        {[1, 2, 3, 4].includes(menuType) && orderStore.orderInfo.length !== 0 &&
                            <div className={classNames(styles.buy_btn_wrapper, styles.order)} >
                                {(orderStore.orderInfo.status === "Closed" ||
                                    orderStore.orderInfo.status === "Закрыта" ||
                                    orderStore.orderInfo.creationStatus === "Paid") ?
                                    <>
                                        <button className={classNames(styles.buy_btn, styles.paid)}>
                                            Заказ оплачен <img src={buy_btn_icon} alt="" />
                                        </button>
                                    </>
                                    :
                                    <>
                                        {menuStore.menu.availablePayments.length > 0 &&
                                            menuStore.menu.availablePayments.find(item => item.id) ?
                                            <button className={styles.buy_btn} onClick={() => goToPaymentSystem()}>
                                                Оплатить заказ <img src={buy_btn_icon} alt="" />
                                            </button>
                                            :
                                            <div>
                                            </div>
                                        }
                                        <button className={styles.buy_btn} >
                                            <img className={styles.waiter_icon} src={waiter_alert_icon} alt="" />
                                        </button>
                                    </>
                                }
                            </div>
                        }

                    </>
                }

                <div className={styles.cart_menu_container}>
                    <button className={styles.cart_menu_btn} onClick={() => onToggleMenuHandler()}>
                        <img src={shevron_rigth_icon} alt=">" />
                    </button>
                </div>

            </div>
        </>
    );
};

export default observer(Cart);