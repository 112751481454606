import React, { useState } from "react";

import "./Calendar.css";
import "./DateTimePicker.scss";
import "./Clock.css";

import styles from "./DateTimePicker.module.scss";

const CustomDateTimePicker = ({ value, onChange }) => {
  // const [value, onChange] = useState(null);
  const minDate = () => new Date();
  const maxDate = () => {
    const now = new Date();
    const res = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 58, now.getHours(), now.getMinutes());
    return res;
  };

  return (
    <div className={styles.date_time_picker}>
      <input
        type={"datetime-local"}
        min={minDate}
        max={maxDate}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default CustomDateTimePicker;
