import React from 'react';

import OrderItem from '../../OrderList/OrderItem/OrderItem'

import styles from '../CartList.module.scss';

const OrderListInCart = ({ items }) => {
    return (
        <div className={styles.item_list}>
            {items?.length > 0 &&
                items.map(item => <OrderItem key={item.id + Math.random()} data={item} />)
            }
        </div>
    );
};

export default OrderListInCart;