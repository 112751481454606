import { makeAutoObservable } from "mobx";

import ConnectionManager from '../http/axios';
import cartStore from "./cartStore";

import menuStore from "./menuStore";
import orderStore from "./orderStore";

class payStore {

    orderInfoTimeoutId

    constructor() {
        makeAutoObservable(this);
    }

    async postPaymentDataOnServer(values) {
        menuStore.setShowForm({ type: 'orderLoading' });
        console.debug("postPaymentDataOnServer", values)

        const data = this.prepareDataOrderData(values);

        // console.debug("postPaymentDataOnServer data", data)
        try {
            const orderRes = await ConnectionManager.GetInstance().GetClient().post(`/order`, data);
            localStorage.setItem("orderId", orderRes.data.id)
            //orderRes = id -Guid

            console.debug("postPaymentDataOnServer", data) //+

            //деление по типам платежной системы
            if (data.paymentType?.id && menuStore.menu.type === 2) { //!!! bug001 исправить не по
                //заказ на доставку + СloudPay

                this.createInvoice(orderRes.data.id)
                    .then(data => {
                        ///000
                        console.log("CloudPay res data", data);
                        window.location.href = data.link; //редирект на внешнюю форму оплаты
                        cartStore.setCart([]);
                        sessionStorage.setItem('cart', "[]");
                    })
            } else if (menuStore.menu.type === 2) {
                //заказ на доставку
                console.log("menuStore.menu.type === 2", menuStore.menu.type === 2);

                await ConnectionManager.GetInstance().GetClient().post(`/order/push`, { id: orderRes.data.id })
                    .then(data => {
                        // ///000
                        window.location.href = `/showcase/${localStorage.getItem("menuId")}/order/${orderRes.data.id}`;
                        cartStore.setCart([]);
                        sessionStorage.setItem('cart', "[]")
                    })
                    .catch((error) => {
                        console.log("func payStore postPaymentDataOnServer /order/push", error.response.data[0]);
                        menuStore.setShowForm({ type: 'error', payload: error.response.data.title })
                        // console.log("menuStore.showForm", menuStore.showForm)
                        // throw error;
                    });
            } else {
                //все остальные заказы
                console.log("else");

                await ConnectionManager.GetInstance().GetClient().post(`/order/push`, { id: orderRes.data.id })
                    .then(data => {
                        // cartStore.setCart([]);
                        // sessionStorage.setItem('cart', "[]")
                    })
                    .catch((error) => {
                        console.error("func payStore postPaymentDataOnServer /order/push", error.message);
                        menuStore.setShowForm({ type: 'error', payload: error.response.data.title })
                        throw error;
                    });
            }
            console.log("return postPaymentDataOnServer orderRes.data", orderRes.data)

            // menuStore.setShowForm({ type: '' });
            return orderRes.data;
        } catch (error) {
            console.log("ошибка функции postPaymentDataOnServer", JSON.parse(error))
            menuStore.setShowForm({ type: 'error', payload: error.response.data.title })
        }
    }

    prepareDataOrderData(values) {
        const menuType = menuStore.menu.type

        let data = JSON.parse(JSON.stringify(values));

        data.items = this.prepareItemsForOrder(data.items);

        switch (menuType) {
            case 0: break;  //витрина
            case 1:
                //table service
                const tableId = localStorage.getItem("tableId");
                const tableNumber = localStorage.getItem("tableNumber");
                if (tableId && tableId !== "none") {
                    data.tableId = tableId;
                    data.tableNumber = tableNumber;
                }
                break;
            case 2: break;  //доставка
            case 3:
                //расходная накладная
                data.stockId = localStorage.getItem("tableId");
                data.contragentInn = localStorage.getItem("contragentInn");
                data.contragentId = orderStore.activeContragent.id;
                break;
            case 4:
                //приходная накладная
                data.stockId = localStorage.getItem("tableId");
                data.contragentInn = localStorage.getItem("contragentInn");
                data.contragentId = orderStore.activeContragent.id;
                break;
            default: break;
        }
        return data;
    }

    async createInvoice(id) {
        try {
            const res = await ConnectionManager.GetInstance().GetClient().post(`/order/createinvoice`, { id });
            return res.data;
        } catch (error) {
            console.log("payStore createInvoice", error.message)
            menuStore.setShowForm({ type: 'error', payload: error.response.data.title })
        }
    }

    async additionalOrderPush(values) {
        try {
            //orderId, items
            let data = JSON.parse(JSON.stringify(values));
            data.items = this.prepareItemsForOrder(data.items);
            const res = await ConnectionManager.GetInstance().GetClient().post(`/order/AddItemsToOrder`, data);
            return res.data;
        } catch (error) {
            console.log("payStore additionalOrderPush", error.message)
            menuStore.setShowForm({ type: 'error', payload: error.response.data.title })
        }
    }



    prepareItemsForOrder(items) {
        for (let item of items) {
            if (item.groupModifiers) {
                for (let groupModifier of item.groupModifiers) {
                    groupModifier.childModifiers = groupModifier.childModifiers.filter(childModifier => childModifier.amount > 0)
                }
            }
        }

        return items.map( //пересобираю каждый айтем в заказе
            ({ id,
                name,
                amount,
                price,
                item,
                modifiers,
                groupModifiers,
                size,
                images }) => {

                const mutateModifier = (modItem) => {
                    const menuProduct = menuStore.findProductInMenu(modItem.id)
                    modItem.item = menuProduct.item;
                    modItem.name = menuProduct.name;
                }

                for (let modKey in modifiers) {
                    mutateModifier(modifiers[modKey])
                }

                for (let groupModKey in groupModifiers) {
                    mutateModifier(groupModifiers[groupModKey])

                    const childModifiers = groupModifiers[groupModKey].childModifiers;

                    for (let modKey in childModifiers) {
                        mutateModifier(childModifiers[modKey])
                    }
                }

                let itemData = {
                    id,
                    name,
                    amount,
                    price,
                    item,
                    modifiers,
                    groupModifiers,
                    size,
                    image: ''
                    //!!! костыль 
                };
                if (itemData.size === '') delete itemData.size;

                return itemData;
            })
    }

}

export default new payStore();
