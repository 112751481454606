import menuStore from "../store/menuStore";

export const inViewTimeout = (currentItem, swiper) => {

    if (menuStore.timerId) {
        clearTimeout(menuStore.timerId)
    }

    const timerId = setTimeout(() => {
        menuStore.setActiveTopBarId(currentItem.id);

        for (let slideIndex in swiper.slides) {
            if (swiper.slides[slideIndex].dataset.id === currentItem.id) {
                swiper.slideTo(slideIndex)
            }
        }
    }, 150);

    menuStore.setTimerId(timerId);

}