import React from 'react';
import { Field } from 'formik';

import styles from '../../forms.module.scss';

const CustomInput = ({
    type = "text",
    name,
    placeholder = '',
    customProps = {},
    label = '',
    isRequired = false,
    error = false,

    component,
    value = '',
    country = 'ru',
    onChange = () => { },
    disableDropdown = true,
    countryCodeEditable = false,
    onBlur = () => { },

    as
}) => {

    const {
        errors
    } = customProps;

    return (
        <div className={styles.form_field_container}>
            {label ?
                <div className={styles.input_title}>
                    {label} {isRequired ? <span className={styles.required_star}>*</span> : ""}
                </div>
                :
                <div className={styles.input_title}>
                </div>
            }
            <div className={styles.input_container}>
                {component ?
                    <Field
                        type={type}
                        name={name}
                        placeholder={placeholder ? placeholder : label}

                        component={component}
                        value={value}
                        country={country}
                        onChange={onChange}
                        disableDropdown={disableDropdown}
                        countryCodeEditable={countryCodeEditable}
                        onBlur={onBlur}
                    />
                    :
                    <Field as={as} type={type} name={name} placeholder={placeholder ? placeholder : label} className={styles.test}/>

                    /*            
                        <Field
                            className={styles.input_container}
                            as="textarea"
                            type="text"
                            name="comment"
                            placeholder={"Введите комментарий"}
                        /> 
                    */
                }
            </div>
            {error ? <div className={styles.form_validation}>{error}</div> : ''}
        </div>
    );
};

export default CustomInput;