import classNames from 'classnames';
import Spinner from 'components/forms/LoadingPopup/Spinner';
import React, { useState } from 'react';
import menuStore from 'store/menuStore';
import orderStore from 'store/orderStore';
import buy_btn_icon from '../../../img/buy_btn_icon.svg';
import Select from 'react-select';

import styles from '../Cart.module.scss';
import { observer } from 'mobx-react-lite';

const InnInput = () => {

    const onChangeContragentHandler = (e) => {
        orderStore.setContragentInn(e.target.value);
    }

    const [isContagentsLoading, setIsContragentsLoading] = useState(false);

    const getContragentHandler = (e) => {
        setIsContragentsLoading(true);
        menuStore.setShowForm({ type: 'spinner' });

        const showcaseId = menuStore.menu.id
        let inn = orderStore.contragentInn;
        inn = inn.trim();
        orderStore.getContragent({ inn, showcaseId })
            .then(data => {
                orderStore.setActiveContragent(data?.[0])

                menuStore.setShowForm({ type: '' })
                setIsContragentsLoading(false);

                if (orderStore.contragents?.length === 0) {
                    menuStore.setShowForm({ type: "messagePopup", payload: "Список контрагентов пуст" })
                }
            })
            .catch(res => {
                menuStore.setShowForm({ type: "messagePopup", payload: "Ошибка получения контрагентов" })
            });
    }

    const contragentOptions = orderStore.contragents?.length > 0 && orderStore.contragents.map(item => ({ ...item, label: item.name, value: item.id }));

    return (
        <>
            <div className={styles.menu_title_inner}>
                <div className={styles.input_title}>
                    ИНН
                </div>
                <input
                    className={styles.inn_input}
                    type="number"
                    placeholder='ИНН'
                    value={orderStore.contragentInn}
                    onChange={(e) => onChangeContragentHandler(e)}
                />
                <button className={classNames(styles.contragent_btn, isContagentsLoading && styles.spinner_wrapper)} onClick={(e) => { getContragentHandler(e) }}>
                    {isContagentsLoading ?
                        <>
                            <div className={classNames(styles.contragent_btn, styles.spinner_wrapper)}>
                                <Spinner mode={"small"} />
                            </div>
                        </>
                        :
                        <>
                            Поиск <img src={buy_btn_icon} alt="" />
                        </>
                    }
                </button>
            </div>

            <div style={{ zIndex: 100 }} className={classNames(styles.inn_select)}>
                <Select
                    isDisabled={orderStore.contragents?.length > 0 ? false : true}
                    className={classNames(styles.inn_select)}
                    defaultValue={contragentOptions?.[0]}
                    onChange={(e) => { orderStore.setActiveContragent(e) }}
                    placeholder={'Выберите контрагента...'}
                    options={contragentOptions}
                />
            </div>
        </>
    );
};

export default observer(InnInput);