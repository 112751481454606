import CustomDateTimePicker from 'components/CustomDateTimePicker';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import orderStore from 'store/orderStore';

import styles from '../Cart.module.scss';

const CartDate = () => {
    return (
        <div className={styles.menu_title_inner}>
            <div className={styles.input_title}>
                К дате
            </div>
            <CustomDateTimePicker
                value={toJS(orderStore.completeBefore)}
                onChange={(e) => { orderStore.setCompleteBefore(e); }}
            />
        </div>
    );
};

export default observer(CartDate);