import React from 'react';
import { observer } from 'mobx-react-lite';
import cartStore from 'store/cartStore';

import styles from '../Cart.module.scss';

const Addon = ({ addon }) => {

    console.debug("Addon", addon)

    return (
        <div>
            <>
                {cartStore.addonsItemList?.map(item => (
                    <div key={item.id} className={styles.addon_inner}>
                        <div>
                            {item.name}
                        </div>
                        <div>
                            {item.price &&
                                <>
                                    {/* {item.price ? `${item.price}` : ''}
                                        {item.amount ? `x ${item.amount}` : ''} */}

                                    {item.price}
                                    &nbsp; x &nbsp;
                                    {item.amount}
                                </>
                            }
                        </div>
                    </div>
                ))}
            </>
        </div>
    );
};

export default observer(Addon);