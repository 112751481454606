import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import Select from 'react-select';

import cartStore from 'store/cartStore';
import menuStore from 'store/menuStore';

import styles from '../Cart.module.scss';

const OrderSelectBox = () => {
    const getOrderOptions = useCallback(() => {
        const orders = JSON.parse(localStorage.getItem("orders"))
        let defaultOptions = [{ label: '---', value: '', payload: null }]
        let options = []
        if (orders?.menuId === menuStore.menu.id) {
            options = orders?.orderInfoList?.map(item => (
                {
                    label: `№${item.externalNumber} - ${item.sum} руб.`,
                    value: item.id,
                    payload: item
                }
            ));
        }
        return options ? [...defaultOptions, ...options] : defaultOptions
    }, [])

    const orderOptions = getOrderOptions();

    const orderSelectOnChangeHandler = (e) => {
        console.log("load order", e.payload)
        if (e.payload) {
            cartStore.replaceCartFromOrderItems(e.payload.items)
        } else {
            cartStore.clearCart();
        }
    }

    return (
        <div style={{ zIndex: 100 }} className={classNames(styles.inn_select)}>
            <Select
                className={classNames(styles.inn_select)}
                isDisabled={orderOptions?.orderInfoList?.length > 0}
                onChange={(e) => orderSelectOnChangeHandler(e)}
                placeholder={'Прошлый заказ...'}
                options={orderOptions}
            />
        </div>
    );
};

export default observer(OrderSelectBox);