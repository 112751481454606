import React from 'react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import menuStore from 'store/menuStore';

import styles from './NotWorkedTimePage.module.scss';

const NotWorkedTimePage = () => {
    const menuId = localStorage.getItem('menuId');
    const tableId = localStorage.getItem('tableId');
    const tableNumber = localStorage.getItem('tableNumber');

    const navigate = useNavigate();
    useEffect(() => {
        menuStore.fetchMenu(menuId);
    }, [menuId])

    useEffect(() => {
        // setTimeout(() => {
        menuStore.fetchMenu(menuId).then(data => {
            if (menuStore.menu.id) {
                let isWeekend = menuStore.isWeekendCheck(menuStore.menu?.hoursOfWork)
                console.log("isWeekend", isWeekend)
                if (!isWeekend) navigate(`/showcase/${menuId}/?tableId=${tableId}&tableNumber=${tableNumber}`)
            }
        })
        // })
    }, [menuId, navigate, tableId, tableNumber]);

    const renderSchedule = () => {
        const dayOfWeek = {
            Monday: "Понедельник",
            Tuesday: "Вторник",
            Wednesday: "Среда",
            Thursday: "Четверг",
            Friday: "Пятница",
            Saturday: "Суббота",
            Sunday: "Воскресенье",
        }

        const sortDayOfWeek = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
        ]

        return (
            <>
                {menuStore.menu?.hoursOfWork &&
                    menuStore.menu?.hoursOfWork
                        .slice().sort((a, b) => sortDayOfWeek.indexOf(a.day) > sortDayOfWeek.indexOf(b.day))
                        .map(item => {
                            return (
                                <div key={item.day + Math.random()} className={styles.item}>
                                    <div className={styles.item_i}>
                                        {dayOfWeek[item.day]}
                                    </div>
                                    <div>
                                        {!item.isWeekend && item.opening}
                                    </div>
                                    <div>
                                        {!item.isWeekend && item.closure}
                                    </div>
                                    <div>
                                        {item.isWeekend ? "выходной" : null}
                                    </div>
                                </div>
                            )
                        })
                }
            </>
        )
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.schedule}>
                <div className={styles.title_h1}>
                    Ресторан закрыт,
                    <br />
                    извините за предоставленные неудобства
                </div>
                <div className={styles.title_h2}>
                    Часы работы
                </div>
                <div className={styles.container}>
                    {renderSchedule()}
                </div>
            </div>
        </div>

    );
};

export default observer(NotWorkedTimePage);