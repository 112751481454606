import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import styles from "./GroupItem.module.scss";
import menuStore from "../../store/menuStore";
import { Link, useParams } from "react-router-dom";

import item_placeholder from "img/item_placeholder.svg";
import classNames from "classnames";

const GroupItem = ({ data = {} }) => {
  const { id } = useParams();
  const elemRef = useRef(null);
  const menuType = menuStore.menu.type;

  const { amount, images, name, price, weight, description, preparing, parent } = data;

  // ??? с рефом не работает
  const onClickHandler = (e) => {
    const elem2 = e.target?.closest("[data-id]")?.dataset.id;
    // const elem2 = elemRef.current;

    setTimeout(() => {
      menuStore.scrollTo(elem2);
    }, 0);
  };

  // console.log("elemRef", elemRef.current)
  return (
    // <Link to={`/showcase/${id}/${data.parent}`}

    <Link
      to={`./group/${data.parent}`}
      className={classNames(styles.item_wrapper, { [styles.item_wrapper_type3]: [3, 4].includes(menuType) })}
      ref={elemRef}
      data-id={data.id}
      onClick={(e) => onClickHandler(e)}
    >
      {![3, 4].includes(menuType) && (
        <div className={styles.image_wrapper}>
          <div className={styles.cart_counter_wrapper}>
            <div className={styles.menu_item_image}>
              {images?.length > 0 ? (
                <img src={images?.[0]} alt="menu_item_image" />
              ) : (
                <img className={styles.placeholder} src={item_placeholder} alt="" />
              )}
            </div>
          </div>
        </div>
      )}

      <div className={styles.menu_item_description}>
        <div className={styles.menu_item_main_description}>
          <div to={`/showcase/${id}/${data.parent}`} className={styles.menu_item_title}>
            {name}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default observer(GroupItem);
