import { makeAutoObservable } from "mobx";
import Moment from "moment";
import { extendMoment } from 'moment-range';

import ConnectionManager from '../http/axios';

import cartStore from "./cartStore";

const moment = extendMoment(Moment);

class menuStore {

    showForm = { type: '', payload: {} };

    timerId;

    isError = false;

    menuid = '';

    menu = {
        items: [],
        addons: [],
    }

    isNavMenuOpen = false;

    activeTopBarId = '';

    showcaseItemsRef = null;

    constructor() {
        makeAutoObservable(this);
    }

    setTimerId(timerId) {
        this.timerId = timerId;
    }

    setIsError(isError) {
        this.isError = isError;
    }

    setMenu(menu) {
        this.menu = menu;
        if (menu.items.filter(item => item.type === 1 && item.parent === null).length > 0) {
            this.menu.items.push({ id: null, parent: null, name: "Другое", type: 2 })
        }
    }

    setShowForm(showForm) {
        this.showForm = showForm;
    }

    setShowcaseItemsRef(showcaseItemsRef) {
        this.showcaseItemsRef = showcaseItemsRef;
    }

    toggleIsNavMenuOpen() {
        this.isNavMenuOpen = !this.isNavMenuOpen;
    }
    setIsNavMenuOpen(isNavMenuOpen) {
        this.isNavMenuOpen = isNavMenuOpen;
    }

    setProductCounter(product, amount) {
        product.amount = amount
    }

    setMenuId(menuid) {
        this.menuid = menuid;
    }

    setRender() {
        this.render = !this.render
    }

    setActiveTopBarId(id) {
        this.activeTopBarId = id;
    }

    addCounterToItem(id, amount) {
        const item = this.findProductInMenu(id);
    }


    findProductInMenu(id, key = "id") {
        const items = this.menu?.items;
        console.log(' findProductInMenu items', items)
        let result = items.filter((item) => item[key] === id)
        console.log('findProductInMenu result [0]', result[0])

        return result[0];
    }

    async fetchMenu(id) {
        try {
            const res = await ConnectionManager.GetInstance().GetClient().get(`/showcase/${id}`);

            this.setMenu(res.data);
            if (this.menu.name) document.title = this.menu.name

            localStorage.setItem("menuId", res.data.id)
            localStorage.setItem("menuType", res.data.type)
            localStorage.setItem("integrationId", res.data.integrationId)



            console.log("menu showcase", res.data)
            return res;
        } catch (error) {
            this.setIsError(true);
            console.error('ошибка fetchMenu', error.message);
        }
    }

    isWeekendCheck(hoursOfWork) {
        const dayOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        ]

        let now = moment();


        if (hoursOfWork?.length > 0) {
            for (let day of hoursOfWork) {
                console.log("now weekday", dayOfWeek[now.day()], day.day)
                if (dayOfWeek[now.day()] === day.day) {
                    if (day.isWeekend) return true

                    const start = moment(day.opening, "hh:mm:ss")
                    const end = moment(day.closure, "hh:mm:ss")
                    const range = moment.range(start, end);

                    console.log("now", now, range.contains(now), "start", start, "end", end, "range", range);
                    if (range.contains(now)) {//range - объект
                        console.log("isWeekendCheck range.contains(now)", range.contains(now));
                        return false;
                    } else {
                        return true
                    }
                }
                // console.log(opening > closure, opening < closure, opening < now, closure < now)

            }
            console.log("isWeekendCheck", false)
            return false;

        }
    }

    scrollTo(elemId) {
        const wrapper = document.querySelector('.showcase-items')
        const headerHeight = document.querySelector('.showcase-header').offsetHeight
        const navigationHeight = document.querySelector('.showcase-navigation').offsetHeight

        if (!elemId) elemId = 'null';
        const scrollToElem = document.querySelector(`div[data-scrolltoid="${elemId}"]`)
        wrapper.scrollTo(0, scrollToElem.offsetTop - headerHeight - navigationHeight) //120
        console.log('scrollToElem scrollTo', scrollToElem)
    }

    findItemId(e) {
        const menuItem = e.target.closest(`div[data-id]`);
        return menuItem.dataset.id
    }

    getItemCounter(id) {
        return cartStore.cart?.filter(item => item.id === id)?.reduce((a, b) => { return a + b.amount }, 0)
    }

    calcItemTotalPrice = (item) => {
        item = JSON.parse(JSON.stringify(item))

        let startPrice = item.price;
        let totalPrice = 0;
        totalPrice = startPrice;

        if (item?.sizes?.length > 0) {
            console.log("values.sizes[0].price", item.sizes[0].price)
            totalPrice = item.sizes[0].price
            for (let size of item.sizes) {
                if (size.id === item.size) {
                    totalPrice = size.price
                }
            }
        }

        if (item.modifiers?.length > 0) {
            for (let modifier of item.modifiers) {
                if (modifier.amount > 0) totalPrice += modifier.price * modifier.amount
            }
        }

        if (item.groupModifiers?.length > 0) {
            for (let groupModifier of item.groupModifiers) {
                for (let modifier of groupModifier.childModifiers) {
                    if (modifier.amount > 0) totalPrice += modifier.price * modifier.amount
                }
            }
        }

        item.totalPrice = totalPrice;

        // console.log("calcItemTotalPrice totalPrice", totalPrice)
        return totalPrice
    }
}

export default new menuStore();