import React from 'react';
import menuStore from 'store/menuStore';
import styles from "components/Cart/CartItem.module.scss";
import { observer } from 'mobx-react-lite';

const ModifierSpan = ({ item }) => {
    const menuItem = menuStore.menu.items.find((menuItem) => menuItem.id === item.id);

    if (menuItem)
        return (
            <div className={styles.item_modifier}>
                {menuItem.name} x {item.amount},{" "}
            </div>
        );
};

export default observer(ModifierSpan);