import { observer } from 'mobx-react-lite';
import React from 'react';

import cartStore from 'store/cartStore';
import menuStore from 'store/menuStore';

import styles from '../CartItem.module.scss';

const CartItem = ({ data }) => {

    const menuType = menuStore.menu.type;

    const {
        name,
        description,
        images,
        price,
        totalPrice,
        id,
        amount,
        cartId,
        modifiers
    } = data;

    const addProductHandler = (e) => {
        console.debug("addProductHandler")
        cartStore.increaseProductCountInCart(cartId);
    }

    const subtractProductHandler = (e) => {
        console.debug("subtractProductHandler")
        cartStore.subtractProductFromCart(cartId);
    }

    const setCounterHandler = (e) => {
        console.log("setCounterHandler", id, e.target.value)
        if (+e.target.value < 0) return
        cartStore.setCounterInCart(id, +e.target.value)
    }

    console.log("totalPrice", totalPrice)

    return (
        <div className={styles.cart_menu_item} data-id={id}>
            <div>
                {![3, 4].includes(menuType) &&
                    <div className={styles.item_image}>
                        {images?.length > 0 &&
                            <img src={images?.[0]} alt="" />
                        }
                    </div>
                }
            </div>
            <div className={styles.descr_wrapper}>
                <div className={styles.item_title}>
                    {name}
                </div>
                <div className={styles.item_modifiers}>
                    {modifiers?.length > 0 && modifiers?.map(item => {
                        const menuItem = menuStore.menu.items.filter(menuItem => menuItem.id === item.id)?.[0]
                        if (menuItem) return <div className={styles.item_modifier} key={item.id}> <span key={item.id}>{menuItem.name} x {item.amount}, </span> </div>
                    })}
                </div>
                <div className={styles.item_footer_cart}>
                    <div className={styles.item_price}>
                        {totalPrice ? totalPrice * amount : price * amount}р.
                    </div>
                    <div className={styles.item_btn_wrapper}>
                        <div className={styles.subtract_item_btn}
                            onClick={(e) => { subtractProductHandler(e) }}
                        >
                            <i className="fa fa-minus"></i>
                        </div>
                        {[3, 4].includes(menuType) ?
                            <input
                                className={styles.item_count_input}
                                type="number"
                                min="0"
                                value={amount}
                                onChange={(e) => { setCounterHandler(e) }}
                            />
                            :
                            <div className={styles.item_count}>
                                {amount}
                            </div>
                        }
                        <div className={styles.add_item_btn}
                            onClick={(e) => { addProductHandler(e) }}
                        >
                            <i className="fa fa-plus"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(CartItem);